import axios from 'axios';

const API_URL = 'https://api.volup.app/api/v1';

// Users endpoints
const uriPages = () => `${API_URL}/pages`;
const uriContact = () => `${API_URL}/utils/contact-message`;
const uriNewsletter = () => `${API_URL}/utils/newsletter`;
const uriRestaurants = (id = '') => `${API_URL}/businesses/landing/${id}`;
const uriHome = () => `${API_URL}/businesses/landing/home`;

// Header generator
const genRequestHeader = () => ({
    headers: {
        Authorization: "Bearer 3NiVCGr4yiMWRMT73g5ijWnao0sPQb.dERxMs9xoPZP0gp7jPLikNV97uC2vJ",
        source: 'landing',
        'accept-language': 'browser',
    },
});

const handleError = (err?: any, dispatch?: any) => {
    if (err.response && err.response.status) {
        if (err.response.status === 400) {
            return err.response.data.message;
        }
        if (err.response.status === 403) {
            return 'Account not confirmed';
        }
        if (err.response.status === 404) {
            return err.response.data.message;
        }
        if (err.response.status === 401) {
            return 'You need to be logged in to access this page.';
        }
        if (err.response.status === 500) {
            return 'The operation could not be concluded';
        }
    }
    return err;
};

const post = (url?: any, body?: any, dispatch?: any, header = genRequestHeader()) =>
    new Promise((resolve, reject) => {
        axios
            // eslint-disable-next-line
            .post(url, body, header)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(handleError(err, dispatch));
            });
    });

const put = (url?: any, body?: any, dispatch?: any, header = genRequestHeader()) =>
    new Promise((resolve, reject) => {
        axios
            // eslint-disable-next-line
            .put(url, body, header)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(handleError(err, dispatch));
            });
    });

const remove = (url?: any, dispatch?: any, header = genRequestHeader()) =>
    new Promise((resolve, reject) => {
        axios
            // eslint-disable-next-line
            .delete(url, header)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(handleError(err, dispatch));
            });
    });

const get = (url?: any, dispatch?: any, header = genRequestHeader()) =>
    new Promise((resolve, reject) => {
        axios
            // eslint-disable-next-line
            .get(url, header)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(handleError(err, dispatch));
            });
    });

const patch = (url?: any, body?: any, dispatch?: any, header = genRequestHeader()) =>
    new Promise((resolve, reject) => {
        axios
            // eslint-disable-next-line
            .patch(url, body, header)
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(handleError(err, dispatch));
            });
    });

export {
    get,
    put,
    post,
    patch,
    remove,

    uriPages,
    uriContact,
    uriRestaurants,
    uriHome,
    uriNewsletter,
};
