import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import "./styles.scss";

export class Card extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            displayInfo: true,
        };
    }

    renderMiniCard() {
        const { image, title, text } = this.props;

        return (
            <div className="MiniCardContainer">
                <div className="CardIconContainer">
                    <img alt="card_image" src={image} />
                </div>
                <div className="MiniCardContent">
                    <div className="CardTitle">
                        <span>{title}</span>
                    </div>
                    <div className="CardContent">
                        <span>{text}</span>
                    </div>
                </div>
            </div>
        );
    }

    invertedCard() {
        const { image, location, title, type, text, height } = this.props;

        return (
            <div
                style={Boolean(height) ? { height } : {}}
                className="CardContainer __inverted"
            >
                <div
                    style={{ backgroundImage: `url(${image})` }}
                    className="CardImageContainer"
                />
                <div className="CardContent">
                    <div className="CardTitle">
                        {Boolean(location) && (
                            <span className="CardLocation">{location}</span>
                        )}
                        <span className="CardRestaurant">{title}</span>
                        <span className="CardType">{type}</span>
                    </div>
                    <div className="CardText">
                        <span>{text}</span>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { image, location, title, type, text, mini, inverted, id, dispatch } =
            this.props;
            
        if (mini) return this.renderMiniCard();
        if (inverted) return this.invertedCard();

        let finalText = text;
        if (finalText.length > 140) {
            finalText = `${text.substring(0, 140)}...`;
        }

        return (
            <div
                onClick={() => {
                    if (id) {
                        dispatch(push(`/restaurant/${id}`));
                    }
                }}
                className={`CardContainer${Boolean(id) ? ' __clickable' : ''}`}
            >
                <div
                    style={{ backgroundImage: `url(${image})` }}
                    className="CardImageContainer"
                />
                <div className="CardTitle">
                    {Boolean(location) && (
                        <span className="CardLocation">{location}</span>
                    )}
                    <span className="CardRestaurant">{title}</span>
                    <span className="CardType">{type}</span>
                </div>
                <div className="CardText">
                    <span>{finalText}</span>
                </div>
            </div>
        );
    }
}

export default connect()(Card);
