import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Row, Col, notification } from "antd";
import { Card } from "components";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import { ClapSpinner } from "react-spinners-kit";

import { getOS, translate } from "utils/utils";
import { ANDROID_LINK, APPLE_LINK } from 'utils/constants';
import { get, post, uriHome, uriNewsletter } from "utils/api";
import { setAppModal, setTransparency } from "store/actions";
import { Input } from "antd";

import Strings from "utils/strings";
import downloadApple from "assets/images/appstore.png";
import downloadAndroid from "assets/images/playstore.png";
import logoGray from "assets/images/logo_gray.png";
import logo_noticia_1 from "assets/images/logos_noticias/BoaCamaBoaMesa.png";
import logo_noticia_2 from "assets/images/logos_noticias/DinheiroVivo.png";
import logo_noticia_3 from "assets/images/logos_noticias/Forbes.png";
import logo_noticia_4 from "assets/images/logos_noticias/ImagensdeMarca.png";
import logo_noticia_5 from "assets/images/logos_noticias/Marketeer.png";
import logo_noticia_6 from "assets/images/logos_noticias/Observador.png";
import logo_noticia_7 from "assets/images/logos_noticias/Publico.png";
import logo_noticia_8 from "assets/images/logos_noticias/Timeout.png";
import placeholder from "assets/images/placeholders/product.png";
import yellow_plus from "assets/images/yellow_plus.png";
import multiorder from "assets/images/multiorder.png";
import plus from "assets/images/plus.png";
import lisbon from "assets/images/lisbon.png";
import porto from "assets/images/porto.png";

// Partner Section Images
import be_partner from "assets/images/backgrounds/be_partner.jpg";
import chef from "assets/images/partnersSection/chef.png";
import highMediumEndRestaurant from "assets/images/partnersSection/highMediumEndRestaurant.png";
import michelin from "assets/images/partnersSection/michelin.png";
import star from "assets/images/partnersSection/star.png";

// Testemonials Section
import aspas_1 from "assets/images/aspas_1.png";
import aspas_2 from "assets/images/aspas_2.png";

// Navigators Section
import navigator from "assets/images/navigator.png";

import "./styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";

export class Home extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            dotsPosition: null,
            loading: true,
            isMobile: window.innerWidth <= 992,
            smallDevice: window.innerWidth < 768,
        };

        this.handleResize = this.handleResize.bind(this);
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        const { dispatch } = this.props;

        this.getData();

        dispatch(setTransparency(true));
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    componentDidUpdate(prevProps: any) {
        const { language } = this.props;

        if (prevProps.language !== language) {
            this.forceUpdate();
        }
    }

    async getData() {
        this.setState({ loading: true });

        const response = await get(uriHome()) as any;

        if (response.status >= 200 && response.status < 400) {
            const { banners, businesses, categories } = response.data.results || {};
            this.setState({ banners, businesses, categories });
        }

        this.setState({ loading: false });
    }

    handleResize() {
        const dotsPosition =
            window.innerWidth > 1920 ? (window.innerWidth - 1920) / 2 : 50;
        const elem = document.getElementsByClassName("Home_Banners_Dots");
        if (elem && elem[0]) {
            // @ts-ignore
            elem[0].style.right = `${window.innerWidth > 1920
                ? dotsPosition + 50
                : window.innerWidth <= 600
                    ? 10
                    : 50
                }px`;
        }

        // eslint-disable-next-line
        const orientation = (screen.orientation || {}).type;

        this.setState({
            isMobile:
                window.innerWidth <= 992 ||
                (orientation === "landscape-primary" && window.innerHeight <= 480) ||
                (orientation === "landscape-secondary" && window.innerHeight <= 480),
            smallDevice: window.innerWidth < 768 ||
                (orientation === "landscape-primary" && window.innerHeight <= 480) ||
                (orientation === "landscape-secondary" && window.innerHeight <= 480),
        });
    }

    handleDownloadButton() {
        const { dispatch } = this.props;

        const os = getOS();

        if (os === "Android") {
            window.open(ANDROID_LINK);
        } else if (os === "iOS") {
            window.open(APPLE_LINK);
        } else {
            dispatch(setAppModal(true));
        }
    }

    canSubmit() {
        const { newsletterName, newsletterEmail } = this.state;

        return Boolean(newsletterName?.trim()) && Boolean(newsletterEmail?.trim());
    }

    async submitForm(event: any) {
        event.preventDefault();

        const { newsletterName, newsletterEmail } = this.state;

        if (!this.canSubmit()) return;

        let response;

        try {
            response = await post(uriNewsletter(), {
                name: newsletterName,
                email: newsletterEmail,
            }) as any;

            if (response.status >= 200 && response.status < 400) {
                notification.success({
                    message: Strings.home.newsletter,
                    description: response.data?.message || Strings.home.newsletterSubscribed,
                    placement: 'bottomRight',
                    duration: 5,
                });

                this.setState({
                    newsletterName: '',
                    newsletterEmail: '',
                });
            } else {
                notification.error({
                    message: Strings.home.newsletter,
                    description: response.data?.message || Strings.home.serverError,
                    placement: 'bottomRight',
                    duration: 5,
                });
            }
        } catch (err) {
            notification.error({
                message: Strings.home.newsletter,
                description: response?.data?.message || Strings.home.serverError,
                placement: 'bottomRight',
                duration: 5,
            });
        }
    }

    renderBanners() {
        const { banners } = this.state;

        const settings = {
            dots: true,
            infinite: true,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            draggable: false,
            dotsClass: "slick-dots Home_Banners_Dots",
        };

        return (
            <div className="Home_Banners">
                <Slider {...settings}>
                    {banners?.map((banner: any, index: number) => (
                        <div key={`banner_${index}`} className="Banner_Individual">
                            <div style={{ backgroundImage: `url('${translate(banner.image)}')` }} className="Banner_Mask" />
                            <div className="Banner_Overlay">
                                <span className="Banner_Title">{translate(banner.title)}</span>
                                <span className="Banner_SubTitle">{translate(banner.subTitle)}</span>
                                <div className="Banner_Btns">
                                    <button
                                        onClick={() => this.handleDownloadButton()}
                                        className="Banner_Button">
                                        {Strings.home.downloadApp}
                                    </button>
                                    <span className="Banner_Button_Text">
                                        {Strings.home.availableOnApps}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }

    renderCarousel() {
        const { businesses } = this.state;
        const { dispatch } = this.props;

        const carouselResponsive = {
            desktop: {
                breakpoint: { max: 4000, min: 1024 },
                items: 3,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
            },
        };

        if (!businesses || !businesses.length) return null;

        return (
            <>
                <div className="Home_Carousel">
                    <Carousel
                        responsive={carouselResponsive}
                        swipeable={true}
                        draggable={true}
                        infinite={true}
                        arrows={true}
                        itemClass="carousel-item-padding"
                    >
                        {businesses?.map((business: any, index: number) => (
                            <Card
                                key={`business_${index}`}
                                image={business.image}
                                location={business.city}
                                title={business.name}
                                type={business.categories?.map((category: any) => translate(category.name)).join(', ')}
                                text={''}
                                id={business._id}
                            />
                        ))}
                    </Carousel>
                </div>
                <Row className="Backpack_Contact_Block" style={{ justifyContent: 'center', alignContent: 'center', flexDirection: 'column', marginTop: 25 }}>
                    <Col md={24} lg={24} style={{}}>
                        <div className="Backpack_Contact">
                            <button onClick={() => dispatch(push('/restaurants'))} className="Form_Button">
                                <span>{Strings.home.viewAll}</span>
                            </button>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }

    renderMultiorder() {
        return (
            <div id="multiorder">
                <div className="Home_Section __rightPadding">
                    <div className="Home_Multiorder"></div>
                    <div className="Home_Section_Title">
                        {Strings.home.orderWithMultiorder}
                    </div>
                    <div className="Home_Section_Text">
                        {Strings.home.multiorderDescription}
                    </div>
                    <img
                        style={{ width: 100, margin: "20px 0" }}
                        src={multiorder}
                        alt="Multiorder" />
                </div>
            </div>
        );
    }

    renderExclusiveMenus() {
        return <div className="Home_Section __leftPadding" style={{ marginTop: 0 }}>
            <div className="Home_ExclusiveMenus"></div>
            <div className="Home_Section_Title">
                {Strings.home.exclusiveMenus}
            </div>
            <div className="Home_Section_Text">
                {Strings.home.exclusiveMenusDescription}
            </div>
        </div>
    }

    renderCategories() {
        const { dispatch } = this.props;
        const { categories } = this.state;

        return (
            <div className="Home_Categories">
                <Row gutter={[30, 30]}>
                    {categories?.slice(0, 5).map((category: any, index: number) => (
                        <Col key={`category_${index}`} xs={index < 3 ? 8 : 0} lg={index < 4 ? 4 : 0} xl={4}>
                            <div
                                className="Category_Card"
                                onClick={() => dispatch(push("/restaurants", { category }))}
                                style={{ backgroundImage: `url(${category.image || placeholder})` }}
                            >
                                <div className="Category_Name">
                                    <span>{translate(category.name)}</span>
                                </div>
                            </div>
                        </Col>
                    ))}
                    <Col xs={24} lg={6} xl={4}>
                        <div className="Category_More">
                            <img className="Button_More_Categories" src={yellow_plus} alt="" onClick={() => dispatch(push("/restaurants"))} />
                            <span>{Strings.home.categories}</span>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    renderAppSection() {
        return (
            <div className="Home_Apps">
                <div className="Home_Overlay" />
                <div className="Home_App_Container">
                    <span className="App_Text">
                        <span className="Volup_Text">Volup </span>
                        <span>{Strings.home.moreThanApp} </span>
                        <span>{Strings.home.experience}</span>
                    </span>
                    <span className="App_Download">{Strings.home.download}</span>
                    <div className="App_Buttons">
                        <a href={APPLE_LINK} target="_blank" rel="noreferrer">
                            <img src={downloadApple} alt="download on app store" />
                        </a>
                        <a href={ANDROID_LINK} target="_blank" rel="noreferrer">
                            <img src={downloadAndroid} alt="download on google play" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    renderVolupBackpack() {
        return (
            <div className="Home_Backpack">
                <Row gutter={30}>
                    <Col xs={24} lg={10}>
                        <div className="Backpack_Container" />
                    </Col>
                    <Col xs={24} lg={{ span: 13, offset: 1 }}>
                        <div className="Backpack_Content">
                            <span className="Backpack_Title">
                                {Strings.home.navigatorsBackpack}
                            </span>
                            <span className="Backpack_Copy1">
                                {Strings.home.outDeliveries}
                            </span>
                            <span className="Backpack_Copy2">{Strings.home.packaging}</span>
                            <span className="Backpack_Copy3">{Strings.home.prototype}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="Backpack_Contact_Block" style={{ justifyContent: 'center', alignContent: 'center', flexDirection: 'column' }}>
                    <Col md={24} lg={24} style={{}}>
                        <div className="Backpack_Contact">
                            <button onClick={() => window.open('https://forms.gle/YxY9Gyk9iyQSuM458')} className="Form_Button">
                                <span>{Strings.home.backpackNavigatorButton}</span>
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    renderLocations() {
        return (
            <div className="Home_Locations">
                <Row>
                    <Col xs={24} lg={14}>
                        <div className="Home_Section" style={{ marginTop: 0, minHeight: 300 }}>
                            <div className="Home_Section_Title">
                                {Strings.home.everExpanding}
                            </div>
                            <div className="Home_Section_Text">
                                {Strings.home.everExpandingDescription}
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} lg={10} style={{ display: 'flex', alignItems: 'center', minHeight: '100px' }}>
                        <img className="LocationImage --image-left" src={lisbon} alt="lisbon" />
                        <img className="LocationPlusImage" src={plus} alt="and" />
                        <img className="LocationImage --image-right" src={porto} alt="porto" />
                    </Col>
                </Row>
            </div>
        );
    }

    renderPartnerSection() {
        return (
            <div id="partner">
                <img style={{ width: "100%" }} src={be_partner} alt="Be a Partner" />
                <div className="Home_Section Partners_Section">
                    <div className="Home_Section_Title">
                        {Strings.home.bePartner}
                    </div>
                    <div className="Home_Section_Text">
                        {Strings.home.bePartnerDescription}
                    </div>
                    <div className="PartnersSection_ListItems">
                        <div className="PartnersSection_ListItems_Item">
                            <img src={michelin} alt="Michelin" />
                            {Strings.home.michelin}
                        </div>
                        <div className="PartnersSection_ListItems_Item">
                            <img src={highMediumEndRestaurant} alt="High Medium End Restaurant" />
                            {Strings.home.highMediumEndRestaurant}
                        </div>
                        <div className="PartnersSection_ListItems_Item">
                            <img src={star} alt="Star" />
                            {Strings.home.star}
                        </div>
                        <div className="PartnersSection_ListItems_Item">
                            <img src={chef} alt="Chef" />
                            {Strings.home.chef}
                        </div>
                    </div>
                </div>
                <Row className="Backpack_Contact_Block" style={{ justifyContent: 'center', alignContent: 'center', flexDirection: 'column', marginTop: 25 }}>
                    <Col md={24} lg={24} style={{}}>
                        <div className="Backpack_Contact">
                            <button onClick={() => window.open('https://0dvkqcro4f7.typeform.com/to/OxgkvYkZ')} className="Form_Button">
                                <span>{Strings.contact.subject1}</span>
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    renderTestimonials() {
        const { isMobile } = this.state;
        const testimonials = [
            {
                name: 'Maria',
                restaurant: 'Misc by Tartar-ia',
                testimonial: Strings.home.testimonial1,
            },
            {
                name: 'Vitor Sobral',
                restaurant: 'Tasca & Peixaria da Esquina',
                testimonial: Strings.home.testimonial2,
            },
            {
                name: 'Hotel Corinthia',
                restaurant: 'Erva & Soul Garden',
                testimonial: Strings.home.testimonial3,
            },
        ];

        const carouselResponsive = {
            mobile: {
                breakpoint: { max: 992, min: 0 },
                items: 1,
                slidesToSlide: 1,
            },
        };

        if (isMobile) {
            return (
                <div className="Home_Chef_Testimonials2">
                    <Carousel
                        responsive={carouselResponsive}
                        swipeable={true}
                        draggable={true}
                        arrows={false}
                        showDots={true}
                        autoPlay={true}
                        autoPlaySpeed={5000}
                        infinite={true}
                        renderDotsOutside
                        itemClass="carousel-item-padding"
                    >
                        {testimonials.map((chef: any, index: number) => {

                            return (
                                <div key={`chef_testimonial_${index}`} className="Chef_Testimonials2_Chef">
                                    <p>{chef.name}</p>
                                    <small>{chef.restaurant}</small>
                                    <div className="Chef_Testimonials2">
                                        <img className="Aspas __1" src={aspas_1} alt="" />
                                        {chef.testimonial}
                                        <img className="Aspas __2" src={aspas_2} alt="" />
                                    </div>
                                </div>
                            )
                        })}
                    </Carousel>
                </div>
            )
        }

        return (
            <div className="Home_Chef_Testimonials2">
                {testimonials.map((chef: any, index: number) =>
                    <div key={`chef_testimonial_${index}`} className="Chef_Testimonials2_Chef">
                        <p>{chef.name}</p>
                        <small>{chef.restaurant}</small>
                        <div className="Chef_Testimonials2">
                            <img className="Aspas __1" src={aspas_1} alt="" />
                            {chef.testimonial}
                            <img className="Aspas __2" src={aspas_2} alt="" />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderNavigators() {
        const { smallDevice } = this.state;

        if (smallDevice) {
            return (
                <React.Fragment>
                    <div className="Home_Section Home_Navigators">
                        <img src={navigator} alt="navigator" />
                    </div>
                    <div className="Home_Navigators_Content_Mobile">
                        <div className="Home_Section_Title">
                            {Strings.home.ourNavigators}
                        </div>
                        <div className="Home_Section_Text">
                            {Strings.home.ourNavigatorsDescription1}
                        </div>
                        <div className="Home_Section_Text">
                            {Strings.home.ourNavigatorsDescription2}
                        </div>
                    </div>
                    <div className="Backpack_Contact_Block">
                        <div className="Backpack_Contact">
                            <button onClick={() => window.open('https://form.jotform.com/212455294744056')} className="Form_Button">
                                <span>{Strings.contact.subject2}</span>
                            </button>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div className="Home_Section Home_Navigators">
                    <img src={navigator} alt="navigator" />
                    <div className="Home_Navigators_Content">
                        <div className="Home_Section_Title">
                            {Strings.home.ourNavigators}
                        </div>
                        <div className="Home_Section_Text">
                            {Strings.home.ourNavigatorsDescription1}
                        </div>
                        <div className="Home_Section_Text">
                            {Strings.home.ourNavigatorsDescription2}
                        </div>
                    </div>
                </div>
                <div className="Backpack_Contact_Block">
                    <div className="Backpack_Contact">
                        <button onClick={() => window.open('https://form.jotform.com/212455294744056')} className="Form_Button">
                            <span>{Strings.contact.subject2}</span>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderNewsletter() {
        const { newsletterName, newsletterEmail, smallDevice } = this.state;

        return (
            <>
                <div className="Home_Section" style={{ textAlign: 'center' }}>
                    <div className="Home_Section_Title">
                        {Strings.home.subscribeNewsletter}
                    </div>
                    <div className="Home_Section_Text">
                        {Strings.home.subscribeNewsletterDescription}
                    </div>
                    <form id="newsletter_form" onSubmit={(e) => this.submitForm(e)}>
                        <Row
                            className="Backpack_Contact_Block"
                            gutter={[20, 10]}
                            style={{ marginTop: 20, alignItems: 'center' }}>
                            <Col md={24} lg={9} style={{ width: '100%' }}>
                                <Input
                                    style={{
                                        height: 50,
                                        borderColor: 'grey'
                                    }}
                                    type="text"
                                    required
                                    value={newsletterName || ''}
                                    placeholder={Strings.contact.name}
                                    onChange={(e: any) => this.setState({ newsletterName: e.target.value })}
                                />
                            </Col>
                            <Col md={24} lg={9} style={{ width: '100%' }}>
                                <Input
                                    style={{
                                        height: 50,
                                        borderColor: 'grey'
                                    }}
                                    type="email"
                                    required
                                    value={newsletterEmail || ''}
                                    placeholder={Strings.contact.email}
                                    onChange={(e: any) => this.setState({ newsletterEmail: e.target.value })}
                                />
                            </Col>
                            <Col md={24} lg={6}>
                                <div className="Backpack_Contact">
                                    <button
                                        type="submit"
                                        style={{ marginTop: smallDevice ? 10 : 0 }}
                                        className="Form_Button">
                                        <span>{Strings.home.subscribe}</span>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </>
        );
    }

    renderBrands() {
        return (
            <div id="brands">
                <div className="Home_Brands">
                    <div className="Brand_Gallery">
                        <a href="https://boacamaboamesa.expresso.pt/boa-mesa/take-away/2021-01-19-Volup-a-nova-aplicacao-que-leva-comida-premium-ao-domicilio" target="_blank" rel="noreferrer"><img src={logo_noticia_1} alt="Boa Cama Boa Mesa" /></a>
                        <a href="https://www.dinheirovivo.pt/fazedores/volup-entregas-com-toque-de-chef-querem-chegar-ao-porto-neste-ano-13632414.html" target="_blank" rel="noreferrer"><img src={logo_noticia_2} alt="Dinheiro Vivo" /></a>
                        <a href="https://www.forbespt.com/plataforma-volup-reforca-presenca-e-entra-em-cascais/" target="_blank" rel="noreferrer"><img src={logo_noticia_3} alt="Forbes" /></a>
                        <a href="https://www.imagensdemarca.pt/artigo/volup---a-app-que-leva-refeicoes-preparadas-por-chefs-ate-casa/" target="_blank" rel="noreferrer"><img src={logo_noticia_4} alt="Imagens de Marca" /></a>
                        <a href="https://marketeer.sapo.pt/volup-o-servico-de-delivery-premium-que-nao-quer-concorrer-com-uber-eats-ou-glovo" target="_blank" rel="noreferrer"><img src={logo_noticia_5} alt="Marketeer" /></a>
                        <a href="https://observador.pt/2021/01/21/a-ideia-e-portuguesa-as-refeicoes-sao-premium-testamos-a-volup-a-nova-plataforma-de-entregas/" target="_blank" rel="noreferrer"><img src={logo_noticia_6} alt="Observador" /></a>
                        <a href="https://www.publico.pt/2021/01/18/fugas/noticia/volup-entrega-refeicoes-casa-servico-luvas-brancas-1946765" target="_blank" rel="noreferrer"><img src={logo_noticia_7} alt="Público" /></a>
                        <a href="https://www.timeout.pt/lisboa/pt/noticias/volup-nova-app-de-entregas-leva-lhe-o-eleven-ou-o-kanazawa-a-casa-122220" target="_blank" rel="noreferrer"><img src={logo_noticia_8} alt="Timeout" /></a>
                    </div>
                </div>
            </div>
        );
    }
    renderContact() {
        const { dispatch } = this.props;
        return (
            <div className="Home_Contact">
                <Row style={{ marginTop: 50, justifyContent: 'center', alignContent: 'center', flexDirection: 'column' }}>
                    <Col md={24} lg={24} style={{}}>
                        <div className="Home_Form_Contact">
                            <img src={logoGray} alt="logo gray" width="100"></img>
                            <h3 className="Home_Form_Title">{Strings.header.sendMessage}</h3>
                            <button onClick={(e: any) => { e.preventDefault(); dispatch(push('/contact')) }} className="Form_Button">
                                <span>{Strings.header.toContact}</span>
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        const { loading } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Volup | Prime Food Delivery</title>
                    <meta name="description" content="Home page description" />
                </Helmet>
                <div className={`App_Loader${loading ? '' : ' __loaded'}`}>
                    {loading && (
                        <ClapSpinner size={60} loading={true} frontColor="#F5A623" backColor="#0A0A1B" />
                    )}
                </div>
                <div className="Home_Container">
                    <div className="Home_Background" />
                    {this.renderBanners()}
                    <div className="Home_Content">
                        {this.renderCategories()}
                        {this.renderCarousel()}
                        {this.renderMultiorder()}
                        {this.renderExclusiveMenus()}
                        {this.renderLocations()}
                        {this.renderPartnerSection()}
                        {this.renderTestimonials()}
                        {this.renderNavigators()}
                        {this.renderNewsletter()}
                        {this.renderBrands()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
});

export default connect(mapStateToProps)(Home);
