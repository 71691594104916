import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Row, Col } from 'antd';

import './styles.scss';
import logo from 'assets/images/logo.png';
import Strings from 'utils/strings';

export class Footer extends React.PureComponent<any, any> {
    render() {
        const { dispatch } = this.props;

        return (
            <footer className="Footer_Container">
                <div className="Footer">
                    <Row style={{ width: '100%' }} gutter={[0, 50]}>
                        <Col xs={24} lg={3}>
                            <div className="Footer_Logo">
                                <img src={logo} alt="Footer Logo" />
                            </div>
                        </Col>
                        <Col xs={12} lg={{ span: 4, offset: 1 }}>
                            <div className="Footer_Menu">
                                <span className="Footer_Menu_Title">{Strings.footer.cities}</span>
                                <a href="/restaurants" onClick={(e: any) => { e.preventDefault(); dispatch(push('/restaurants', { filter: 'Lisboa' })) }} className="Footer_Menu_Item">Lisboa</a>
                                <a href="/restaurants" onClick={(e: any) => { e.preventDefault(); dispatch(push('/restaurants', { filter: 'Cascais' })) }} className="Footer_Menu_Item">Cascais</a>
                                <a href="/restaurants" onClick={(e: any) => { e.preventDefault(); dispatch(push('/restaurants', { filter: 'Porto' })) }} className="Footer_Menu_Item">Porto</a>
                            </div>
                        </Col >
                        <Col xs={12} lg={4}>
                            <div className="Footer_Menu">
                                <span className="Footer_Menu_Title">{Strings.footer.about}</span>
                                <a href="/mission" onClick={(e: any) => { e.preventDefault(); dispatch(push('/mission')) }} className="Footer_Menu_Item">{Strings.header.mission}</a>
                                <a href="/terms" onClick={(e: any) => { e.preventDefault(); dispatch(push('/terms')) }} className="Footer_Menu_Item">{Strings.footer.terms}</a>
                                <a href="/privacy" onClick={(e: any) => { e.preventDefault(); dispatch(push('/privacy')) }} className="Footer_Menu_Item">{Strings.footer.privacy}</a>
                            </div >
                        </Col >
                        <Col xs={12} lg={4}>
                            <div className="Footer_Menu">
                                <span className="Footer_Menu_Title">{Strings.footer.social}</span>
                                <a href="https://www.instagram.com/volup_pt/" onClick={(e: any) => { e.preventDefault(); window.open('https://www.instagram.com/volup_pt/') }} className="Footer_Menu_Item">Instagram</a>
                                <a href="https://www.facebook.com/volup.portugal" onClick={(e: any) => { e.preventDefault(); window.open('https://www.facebook.com/volup.portugal') }} className="Footer_Menu_Item">Facebook</a>
                                <a href="https://www.linkedin.com/company/volup/" onClick={(e: any) => { e.preventDefault(); window.open('https://www.linkedin.com/company/volup/') }} className="Footer_Menu_Item">LinkedIn</a>
                                <a href="https://blog.volup.app/" onClick={(e: any) => { e.preventDefault(); window.open('https://blog.volup.app/') }} className="Footer_Menu_Item">Blog Volup</a>
                             
                            </div>
                        </Col>
                        <Col xs={12} lg={{ span: 6, offset: 2 }}>
                            <div className="Footer_Menu __alignRight">
                                <span className="Footer_Menu_Item">{Strings.footer.copyright} &copy; {Strings.footer.volupLimited}</span>
                                <a href="/contact" onClick={(e: any) => { e.preventDefault(); dispatch(push('/contact')) }} className="Footer_Menu_Item">{Strings.footer.sendUsEmail}</a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = (state: any) => ({
    router: state.router,
})

export default connect(mapStateToProps)(Footer);