import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Col, Row } from 'antd';

import { setTransparency } from 'store/actions';

import Strings from 'utils/strings';
import logoMini from 'assets/images/volup_mini.png';
import './styles.scss';

export class Mission extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            isMobile: document.body.clientWidth <= 768,
        };

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(setTransparency(false));

        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({ isMobile: document.body.clientWidth <= 768 });
    }

    renderHistory() {
        return (
            <div className="Mission_History">
                <Row>
                    <Col xs={24} lg={10}>
                        <div className="History_Image" />
                    </Col>
                    <Col xs={24} lg={14}>
                        <div className="History_Image_Content">
                            <img src={logoMini} alt="logo mini" />
                            <span className="History_Title">{Strings.mission.history}</span>
                            <span className="History_Text">{Strings.mission.history_text1}</span>
                            <span className="History_Text">{Strings.mission.history_text2}</span>
                            <span className="History_Text">{Strings.mission.history_text3}</span>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    renderTeam() {
        return (
            <div className="Mission_Team">
                <Row>
                    <Col xs={24} lg={14}>
                        <div className="Team_Image_Content">
                            <span className="Team_Title">{Strings.mission.team}</span>
                            <span className="Team_Text"><b>{Strings.mission.team_subtitle}</b></span>
                            <span className="Team_Text">{Strings.mission.team_text1}</span>
                        </div>
                    </Col>
                    <Col xs={24} lg={10}>
                        <div className="Team_Image" />
                    </Col>
                </Row>
            </div>
        );
    }
    renderTeamFoto(){
        return(
            <div className="Mission_Team_Foto">
                 <Row>
                    <Col xs={24} lg={24}>
                        <div className="Mission_Team_Foto_Content">
                         
                        </div>
                    </Col>
                  
                </Row>
            </div>
        )
    }

    renderContact() {
        const { dispatch } = this.props;

        return (
            <div className="Mission_Contact">
                <img src={logoMini} alt="logo mini" />
                <span className="Contact_Title">{Strings.mission.wannaBePart}</span>
                <button onClick={() => dispatch(push('/contact'))} className="Contact_Button">
                    <span>{Strings.header.toContact}</span>
                </button>
            </div>
        )
    }

    render() {
        return (
            <div className="Mission_Container">
                <div className="Mission_Background" />
                <div className="Mission_Content">
                    {this.renderHistory()}
                    {this.renderTeam()}
                    {this.renderTeamFoto()}
                    {this.renderContact()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    router: state.router
});

export default connect(mapStateToProps)(Mission);