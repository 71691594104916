import React from 'react';
import { connect } from 'react-redux';
import { Tooltip, Image } from 'antd';

import './styles.scss';

export class ProductCard extends React.PureComponent<any, any> {
    renderPrice(value: number) {
        return `${value.toFixed(2)}€`;
    }

    render() {
        const { image, name, description, price, specialPrice, isPlaceholder } = this.props;

        let newName = name;
        if (newName.length > 35 && description) {
            newName = `${newName.substring(0, 35)}...`;
        }

        let newDescription = description;
        if (newDescription.length > 50) {
            newDescription = `${newDescription.substring(0, 50)}...`;
        }

        return (
            <div className="Product_Card_Container">
                <div className="Product_Image">
                    {isPlaceholder ?
                        <img src={image} alt="placeholder" />
                        :
                        <Image src={image} width="100%" height="100%" />}
                </div>
                <div className="Product_Information">
                    <div className="Product_Header">
                        <Tooltip title={name} color="#141433">
                            <div className="Product_Title">
                                <span>{newName}</span>
                            </div>
                        </Tooltip>
                        <Tooltip title={description} color="#141433">
                            <div className="Product_Description">
                                <span>{newDescription}</span>
                            </div>
                        </Tooltip>
                    </div>
                    <div className="Product_Pricing">
                        <span className="Product_Price">{this.renderPrice(specialPrice != null ? specialPrice : price)}</span>
                        {specialPrice != null && (
                            <span className="Product_Old_Price">{this.renderPrice(price)}</span>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(ProductCard);