import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { setLanguage } from "store/actions";
import {
    Home,
    Mission,
    Restaurant,
    Restaurants,
    ChefsAtHome,
    Terms,
    Privacy,
    Contact,
    Cookies,
} from "screens";
import { Footer, Header } from "components";
import CookieConsent from "react-cookie-consent";
import { Redirect, Route, Switch } from "react-router-dom";
import history from "utils/history";

import "styles/styles.scss";
import "antd/dist/antd.css";
import Strings from "utils/strings";


export class App extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        const { language: defaultLanguage, dispatch } = props;

        // @ts-ignore
        const language = window.navigator.userLanguage || window.navigator.language;
        let finalLang;

        switch (String(language).substring(0, 2).toLowerCase()) {
            case 'pt':
                finalLang = 'pt';
                break;
            case 'en':
                finalLang = 'en';
                break;
            case 'es':
                finalLang = 'es';
                break;
            case 'fr':
                finalLang = 'fr';
                break;
            default:
                finalLang = 'pt';
                break;
        }

        // @ts-ignore
        Strings.setLanguage(defaultLanguage || finalLang);
        // @ts-ignore
        dispatch(setLanguage(defaultLanguage || finalLang));
    }

    componentDidUpdate(prevProps: any) {
        const { router: prevRouter } = prevProps;
        const { router } = this.props;

        if (router.location.pathname !== prevRouter.location.pathname) {
            window.scrollTo({ top: 0 });
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    renderScreens() {
        return (
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/restaurants" component={Restaurants} />
                <Route exact path="/restaurant/:id" component={Restaurant} />
                <Route exact path="/chefathome" component={ChefsAtHome} />
                <Route exact path="/mission" component={Mission} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/cookies" component={Cookies} />
                <Route exact path="/contact" component={Contact} />
                <Redirect to="/" />
            </Switch>
        );
    }

    render() {
        const { dispatch } = this.props;

        return (
            <div id="app_content" className="Main_App">
                <div className="Main_Background" />
                <Header history={history} />
                {this.renderScreens()}
                <Footer />
                <CookieConsent
                    enableDeclineButton
                    flipButtons
                    location="bottom"
                    cookieName="volupCookie"
                    expires={999}
                    overlay
                    overlayStyle={{ background: '#000000b3' }}
                    buttonText={Strings.home.cookieAccept}
                    declineButtonText={Strings.home.cookieDecline}
                    style={{ 
                        display: 'block',
                    }}
                    buttonStyle={{
                        background: '#141433',
                        color: "white",
                        fontWeight: "bolder",
                        textShadow: "2px 2px black",
                    }}
                >
                    {Strings.formatString(Strings.home.cookies as any, {
                        link: (
                            <a href="/cookies" onClick={(e: any) => {
                                e.preventDefault();
                                dispatch(push('/cookies'));
                            }}>
                                {Strings.home.cookiePolicy}
                            </a>
                        ),
                    })}
                </CookieConsent>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    router: state.router,
    language: state.language,
});

export default connect(mapStateToProps)(App);
