import { store } from 'store';

export const translate = (text: any) => {
	if (!text) return '';

	if (typeof text === 'string') return text;

    // @ts-ignore
	const lang = store.getState().language;
	const defaultLanguage = 'pt';

	if (text[defaultLanguage] || text[lang]) {
		return text[lang] || text[defaultLanguage];
	}

	return Object.values(text).filter(val => !!val)[0] || '';
};

export const getOS = () => {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}