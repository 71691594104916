import React from "react";
import { connect } from "react-redux";
import { setTransparency } from "store/actions";
import { Col, Row } from "antd";
import Carousel from "react-multi-carousel";

import Strings from "utils/strings";
import "./styles.scss";
import icone1 from "assets/images/chef/Icone_1.png";
import icone2 from "assets/images/chef/Icone_2.png";
import icone3 from "assets/images/chef/Icone_3.png";
import leftImg from "assets/images/chef/left_img.png";
import rightImg from "assets/images/chef/right_img.png";
import BertilioGomes from "assets/images/chef/Chefs/BertilioGomes.png";
import ManuelLino from "assets/images/chef/Chefs/ManuelLino.png";
import MartimEstevesMarcos from "assets/images/chef/Chefs/MartimEstevesMarcos.png";
import MarleneVieira from "assets/images/chef/Chefs/MarleneVieira.png";
import PauloMorais from "assets/images/chef/Chefs/PauloMorais.png";
import LuisBaena from "assets/images/chef/Chefs/LuisBaena.png";
import TwoPack from "assets/images/chef/Chefs/TwoPack.png";
import VicentFarges from "assets/images/chef/Chefs/VicentFarges.png";
import VitorSobral from "assets/images/chef/Chefs/VitorSobral.png";
import MiguelLaffan from "assets/images/chef/Chefs/MiguelLaffan.png";

export class ChefsAtHome extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isMobile: document.body.clientWidth <= 768
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(setTransparency(false));

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ isMobile: document.body.clientWidth <= 768 });
  }

  renderBanner() {
    return (
      <div className="ChefsAtHome_Banner">
        <Row>
          <Col xs={24} lg={24}>
            <div className="Banner_Image">
              <div className="Banner_Image_Container">
                <h2 className="Banner_Image_Title">Comporta</h2>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderChefsCarousel() {
    const carouselResponsive = {
      desktop: {
        breakpoint: { max: 3840, min: 1025 },
        items: 6,
        slidesToSlide: 1
      },
      tablets: {
        breakpoint: { max: 1024, min: 769 },
        items: 4,
        slidesToSlide: 1
      },
      bigPhone: {
        breakpoint: { max: 768, min: 0 },
        items: 4,
        slidesToSlide: 1
      },
      smallPhones: {
        breakpoint: { max: 480, min: 0 },
        items: 3,
        slidesToSlide: 1
      }
    };

    return (
      <div className="ChefsAtHome_Carousel">
        <Row>
          <Col xs={24} lg={24}>
            <Carousel
              responsive={carouselResponsive}
              swipeable={false}
              draggable={true}
              infinite={true}
              arrows={false}
              showDots={true}
              autoPlay={true}
              autoPlaySpeed={2500}
              renderDotsOutside
              itemClass="carousel-banner-padding"
            >
              <div className="ChefsAtHome_Carousel_Chefs">
                <img alt="BertilioGomes" src={BertilioGomes} />
                <span>Bertilio Gomes</span>
              </div>
              <div className="ChefsAtHome_Carousel_Chefs">
                <img alt="ManuelLino" src={ManuelLino} />
                <span>Manuel Lino</span>
              </div>
              <div className="ChefsAtHome_Carousel_Chefs">
                <img alt="VitorSobral" src={VitorSobral} />
                <span>Vitor Sobral</span>
              </div>
              <div className="ChefsAtHome_Carousel_Chefs">
                <img alt="MarleneVieira" src={MarleneVieira} />
                <span>Marlene Vieira</span>
              </div>
              <div className="ChefsAtHome_Carousel_Chefs">
                <img alt="MartimEstevesMarcos" src={MartimEstevesMarcos} />
                <span>Martim Esteves Marcos</span>
              </div>
              <div className="ChefsAtHome_Carousel_Chefs">
                <img alt="Luis Baena" src={LuisBaena} />
                <span>Luis Baena</span>
              </div>
              <div className="ChefsAtHome_Carousel_Chefs">
                <img alt="TwoPack" src={TwoPack} />
                <span>Two Pack</span>
              </div>
              <div className="ChefsAtHome_Carousel_Chefs">
                <img alt="VicentFarges" src={VicentFarges} />
                <span>Vicent Farges</span>
              </div>
              <div className="ChefsAtHome_Carousel_Chefs">
                <img alt="MiguelLaffan" src={MiguelLaffan} />
                <span>Miguel Laffan</span>
              </div>
              <div className="ChefsAtHome_Carousel_Chefs">
                <img alt="Paulo Morais" src={PauloMorais} />
                <span>Paulo Morais</span>
              </div>
            </Carousel>
          </Col>
        </Row>
      </div>
    );
  }

  renderTextContent() {
    return (
      <div className="ChefsAtHome_TextContent">
        <Row>
          <Col xs={24} lg={24}>
            <div className="Banner_Image_Content">
              <h1 className="Banner_Title">{Strings.chefs.contentTitle}</h1>
              <span className="Banner_Text">{Strings.chefs.contentText_1}</span>
              <span className="Banner_Title">{Strings.chefs.contentSubTitle}</span>
              <span className="Banner_Text">{Strings.chefs.contentText_2}</span>
            </div>
            <img alt="Panela_Equerda_300x200" src={leftImg} className="leftImg" />
            <img alt="Moinho_Right_200x250" src={rightImg} className="rightImg" />
          </Col>
        </Row>
      </div>
    );
  }

  renderThreeBenefits() {
    return (
      <div className="ChefsAtHome_ThreeBenefits">
        <Row>
          <Col xs={24} lg={8}>
            <div className="ThreeBenefits_Block">
              <img alt="service" src={icone1} />
              <h2>
              {Strings.chefs.exclusive}
              </h2>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="ThreeBenefits_Block">
              <img alt="schedule" src={icone2} />
              <h2>
              {Strings.chefs.schedules}
              </h2>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="ThreeBenefits_Block">
              <img alt="handle" src={icone3} />
              <h2>
              {Strings.chefs.relax}
              </h2>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderContact() {
    return (
      <div className="ChefsAtHome_Contact">
        <Row>
          <Col xs={24} lg={24} className="Contact_Card">
            <a href="https://wa.link/55gt50" className="Contact_Button">
              {Strings.chefs.speakwithus}
            </a>
          </Col>
          <Col xs={24} lg={24} className="Contact_Card">
            <span>{Strings.chefs.getintouch}</span>
            <a href="mailto:chef@volup.app">
              chef@volup.app
            </a>
          </Col>
        </Row>        
      </div>
    );
  }

  render() {
    return (
      <div className="ChefsAtHome_Container">
        <div className="ChefsAtHome_Background" />
        <div className="ChefsAtHome_Content">
          {this.renderBanner()}
          {this.renderChefsCarousel()}
          {this.renderTextContent()}
          {this.renderThreeBenefits()}
          {this.renderContact()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  router: state.router
});

export default connect(mapStateToProps)(ChefsAtHome);
