import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
    pt: {
        fields: {
            phone: 'Telefone',
        },
        header: {
            home: 'Home',
            mission: 'Missão',
            restaurants: 'Restaurantes',
            toContact: 'Contacto',
            download: 'Download',
            partner: 'Parceiro Volup',
            news: 'Noticias',
            downloadMenu: 'Download App',
            discover: '{volup} bem mais que uma APP',
            discover2: 'uma experiência',
            language: 'Idioma',
            bannerBtn: 'Ver',
            sendMessage: 'Envie-nos uma mensagem',
        },
        footer: {
            cities: 'Cidades',
            about: 'Sobre',
            social: 'Social',
            copyright: 'Copyright 2021',
            volupLimited: 'Volup Limited',
            sendUsEmail: 'Envie-nos um email',
            blog: 'Blog',
            terms: 'Termos e Condições',
            privacy: 'Política de Privacidade',
        },
        home: {
            showAllCategories: 'Ver todas as categorias e restaurantes',
            weWork: 'Trabalhamos com os melhores chefs e restaurantes.',
            categories: 'Categorias',
            viewAll: 'Ver todos',
            moreThanApp: 'mais do que uma app,',
            experience: 'uma experiência gastronómica',
            download: 'Os melhores restaurantes e pratos de chefes em sua casa.',
            navigatorsBackpack: 'Navigators e a sua Mochila Volup',
            outDeliveries: 'Prime Food & Prime Delivery.',
            packaging: 'Os estafetas, conhecidos como Navigators, são selecionados rigorosamente, cada um recebe uma formação específica para que o pedido chegue até si em perfeitas condições.',
            prototype: 'Desenhadas e fabricadas em Portugal com materiais isotérmicos de topo, as mochilas Volup são a peça chave para o sucesso e acondicionamento de todas as nossas entregas.',
            backpackAreas: 'Áreas para quente e frio',
            backpackAreasText: 'Porque sabemos que cada elemento tem que ser tratado de forma especial.',
            backpackPackaging: 'Packaging Específico',
            backpackPackagingText: 'A sua refeição é preparada com cuidado, e deve ser entregue também, com o mesmo ou mais cuidado.',
            backpackNavigator: 'Queres ser Navigator Volup?',
            backpackNavigatorButton: 'Abrir Formulário',
            locations: 'Onde estamos?',
            locationsSub: 'Levamos o seu restaurante preferido até onde quiser!',
            everExpanding: 'Em constante expansão',
            everExpandingDescription: 'Cada vez mais próximo de si. A Volup está em constante crescimento e trabalhar no sentido de poder oferecer toda a qualidade, dedicação e entrega, onde quer que esteja.',
            exclusiveRestaurants: '{0} restaurantes.',
            wereWorking: 'Noticias em breve, estamos a trabalhar para chegar mais perto de si.',
            chefsJoined: 'Os chefs que se juntaram à Volup',
            chefsJoined2: 'têm algo para lhe dizer.',
            itsPossible: 'Ainda tem dúvidas que se deve juntar à Volup?',
            availableOnApps: 'Disponível em iOS e Android',
            downloadApp: 'Faça o download da App',
            cookies: 'A Volup utiliza cookies para fornecer ao utilizador uma melhor experiência de utilização. Ao continuar a usar os nossos serviços, está a aceitar a {link}.',
            cookiePolicy: 'utilização de cookies',
            cookieAccept: 'Aceitar',
            cookieDecline: 'Recusar',
            multiOrder: 'Multiorder',
            orderWithMultiorder: 'Encomende com Multiorder',
            multiorderDescription: 'Encomende até 3 restaurantes num único pedido apenas. Torna tudo mais simples.',
            exclusiveMenus: 'Menus exclusivos Volup',
            exclusiveMenusDescription: 'Os melhores Chefs preparam pratos e menus exclusivos para si.',

            // Be Partner Section
            bePartner: 'O seu canal de venda online para o chegar ao seu tipo de cliente.',
            bePartnerDescription: 'Ser parceiro Volup significa estar presente numa app que reúne: os melhores restaurantes, as melhores experiências gastronómicas e o maior cuidado no serviço de entrega.\nSomos uma extensão do seu excelente serviço à distância de um clique.',
            michelin: 'Estrelas Michelin',
            highMediumEndRestaurant: 'Restaurante',
            star: 'Hóteis 5 Estrelas',
            chef: 'Chefs',

            // Navigators Sections
            ourNavigators: 'Os nossos Navigators',
            ourNavigatorsDescription1: 'Os nossos estafetas, conhecidos como Navigators, são selecionados rigorosamente para dar o melhor serviço, cada um recebe uma formação específica para que o pedido chegue até si em perfeitas condições.',
            ourNavigatorsDescription2: 'Desenhadas e fabricadas em Portugal com materiais isotérmicos de topo, as mochilas Volup são a peça chave para o sucesso e acondicionamento de todas as nossas entregas.',
            applyAsNavigator: "Candidatar como Navigator",

            // Newsletter Section
            subscribeNewsletter: 'A Newsletter que lhe traz o melhor da gastronomía',
            subscribeNewsletterDescription: 'Considera-se um foodie? É para si e a pensar em si que a Volup em conjunto com os seus parceiros levam até ao seu conforto o melhor da gastronomia. Junte-se a esta familia e receba as nossas novidades em primeira mão.',
            subscribe: 'Subscrever',
            newsletter: 'Newsletter',
            newsletterSubscribed: 'A subscrição à newsletter foi efectuada com sucesso.',
            serverError: 'Não foi possível estabelecer comunicação com o servidor. Por favor, tente mais tarde.',

            testimonial1: ` Volup ter surgido foi a melhor coisa que nos aconteceu, encontrámos um parceiro com quem nos identificamos totalmente e onde sabemos que o nosso produto é sempre muito bem cuidado.`,
            testimonial2: `Consideramos a Volup mais que uma aplicação, tornou-se desde o seu primeiro dia de existência um parceiro de negócios, do qual nos fez olhar para um mercado inteiramente desconhecido para nós como uma grande oportunidade de negócio, a relação de parceria têm sido um sucesso absoluto e com perspetivas risonhas de um futuro em crescendo.`,
            testimonial3: `A Volup revelou ser muito mais do que uma empresa de home delivery, apostando acima de tudo na qualidade de serviço, com o objetivo de elevar a experiência do cliente, levando até sua casa a identidade do restaurante. Utilizar a Volup é sem dúvida uma experiência de qualidade, muito mais do que recorrer a um simples serviço de entrega.`,
        },
        restaurants: {
            showingFilteredResults: 'A mostrar resultados para',
            showingAllResults: 'A mostrar todos os resultados',
            location: 'Localização',
            back: 'Voltar',
            schedule: 'Horário',
            opened: 'Aberto',
            closed: 'Encerrado',
            checkOnApp: 'Ver na App',
            orderOnApp: 'Encomendar',
            description: 'Descrição',
            all: 'Ver tudo',
        },
        chefs: {
            contentTitle: 'O seu Chef, a sua casa',
            contentText_1: `Surgimos no mercado para revolucionar o seu paladar e elevar as suas experiências gastronómicas em casa. \nFaremos questão que coma com todos os seus sentidos: com aquilo que vê, que cheira e que sente…`,
            contentSubTitle: 'Como?',
            contentText_2: `Levando até ao conforto de sua casa os melhores chefes \nFique a conhecer mais deste nosso serviço e não se esqueça..\nNão é apenas um jantar, é uma experiência!`,
            exclusive: `Um serviço \n exclusivo`,
            schedules: `Horários \n Flexiveis`,
            relax: `Relaxe, \n Tratamos de tudo`,
            speakwithus: 'Fale connosco',
            getintouch: 'Ou peça mais informações em',
        },
        days: {
            monday: 'SEG',
            tuesday: 'TER',
            wednesday: 'QUA',
            thursday: 'QUI',
            friday: 'SEX',
            saturday: 'SAB',
            sunday: 'DOM',
        },
        mission: {
            wannaBePart: 'Quer fazer parte desta equipa?',
            history: 'História',
            history_text1: 'A Volup nasceu em dezembro de 2020, com a missão de criar um novo conceito de Delivery – Prime Food, dos melhores chefes portugueses, em sua casa. Desde aí que tem vindo a ganhar o seu lugar no mercado, sendo hoje conhecida como um serviço de excelência, onde a qualidade e a diversidade gastronómica são garantia.',
            history_text2: 'A Volup proporciona uma experiência e combinação desde a cozinha portuguesa, francesa, à japonesa... Porque a comida mais refinada requer cuidados a Volup premeia no seu serviço ao cliente, nos cuidados e acondicionamento no momento das entregas, para que o seu prato preferido chegue até si como acabado de sair da cozinha.',
            history_text3: 'A Volup representa um estilo de vida para as pessoas que querem uma experiência gastronómica, com conforto, ao alcance de um clique. De forma a aproveitar os seus momentos de lazer, em casa, no trabalho, sozinho, em família ou com amigos.',
            team: 'Equipa',
            team_subtitle: 'Prime Food & Prime Staff',
            team_text1: 'Construída por uma equipa jovem, dinâmica, responsável e cheia de energia, que pretende mudar as coisas.Tem como objetivo valorizar e inovar o serviço de entregas ao domicílio, tornando o momento numa experiência.',
        },
        languages: {
            portuguese: 'PT',
            english: 'EN',
            spanish: 'ES',
            french: 'FR',
        },
        contact: {
            title: 'Qualquer dúvida ou pedido de suporte, entre em contacto através do formulário.',
            subTitle: 'Questões sobre o Aplicativo ou outras questões que queira colocar.',
            header: 'Formulário de Contacto',
            subject: 'Selecione o assunto',
            name: 'O seu nome',
            email: 'O seu e-mail',
            message: 'A sua mensagem...',
            send: 'Enviar',
            subject1: "Quero ser parceiro Volup",
            subject2: "Candidatar para navigator",
            subject3: "Imprensa",
            subject4: "Suporte",
            subject5: "Outros assuntos",
            success: 'Sucesso',
            emailSent: 'O email foi enviado com sucesso.',
            error: 'Erro',
            couldNotSend: 'Não foi possível contactar o servidor.',
        },
    },
    en: {
        fields: {
            phone: 'Phone',
        },
        header: {
            home: 'Home',
            mission: 'Mission',
            restaurants: 'Restaurants',
            toContact: 'Contact us',
            download: 'Download',
            partner: 'Volup Partner',
            news: 'News',
            downloadMenu: 'Download App',
            discover: '{volup} more than an app',
            discover2: 'an experience',
            language: 'Language',
            bannerBtn: 'See',
            sendMessage: 'Send us a message',
        },
        footer: {
            cities: 'Cities',
            about: 'About us',
            social: 'Social',
            copyright: 'Copyright 2021',
            volupLimited: 'Volup Limited',
            sendUsEmail: 'Send us an email',
            blog: 'Blog',
            terms: 'Terms & Conditions',
            privacy: 'Privacy Policy',
        },
        home: {
            showAllCategories: 'See all categories and restaurants',
            weWork: 'We work with the best chefs and restaurants.',
            categories: 'Categories',
            viewAll: 'View All',
            moreThanApp: 'more than an app',
            experience: 'an experience',
            download: 'The finest chef restaurants and dishes at your home.',
            navigatorsBackpack: 'Navigators & the Volup Backpack',
            outDeliveries: 'Prime Food & Prime Delivery.',
            packaging: 'The couriers, known as Navigators, are rigorously selected, and each one receives specific training so that the order arrives to you in perfect condition.',
            prototype: 'Designed and manufactured in Portugal with top isothermal materials, Volup backpacks are the key to the success and packaging of all our deliveries.',
            backpackAreas: 'Áreas para quente e frio',
            backpackAreasText: 'Porque sabemos que cada elemento tem que ser tratado de forma especial.',
            backpackPackaging: 'Packaging Específico',
            backpackPackagingText: 'A sua refeição é preparada com cuidado, e deve ser entregue também, com o mesmo ou mais cuidado.',
            backpackNavigator: 'You want to be Navigator Volup?',
            backpackNavigatorButton: 'Open Form',
            locations: 'Where are we?',
            locationsSub: 'We take your favorite restaurant wherever you want!',
            everExpanding: 'Always expanding',
            everExpandingDescription: 'Ever closer to you. Volup is constantly growing and working towards being able to offer all the quality, dedication and delivery, wherever you are.',
            exclusiveRestaurants: '{0} restaurantes.',
            wereWorking: 'News soon, we are working to get closer to you.',
            chefsJoined: 'The chefs who joined Volup',
            chefsJoined2: 'have something to tell you.',
            itsPossible: 'Still have doubts that you should join Volup?',
            availableOnApps: 'Available on iOS and Android',
            downloadApp: 'Download the App',
            cookies: 'Volup uses cookies to provide you with a better user experience. By continuing to use our services, you are accepting {link}.',
            cookiePolicy: 'use of cookies',
            cookieAccept: 'Accept',
            cookieDecline: 'Refuse',
            multiOrder: 'Multiorder',
            orderWithMultiorder: 'Order With Multiorder',
            multiorderDescription: 'Order up to 3 restaurants in a single order. It makes everything so simple.',
            exclusiveMenus: 'Volup Exclusive Menus',
            exclusiveMenusDescription: 'The best Chefs prepare exclusive dishes and menus just for you.',

            // Be Partner Section
            bePartner: 'Your online sales channel to reach your type of customer.',
            bePartnerDescription: 'Being a Volup partner means being present in an app that brings together: the best restaurants, the best gastronomic experiences and the greatest care in delivery service.\nWe are an extension of your excellent service just a click away.',
            michelin: 'Michelin Stars',
            highMediumEndRestaurant: 'Restaurants',
            star: '5-star hotels',
            chef: 'Chefs',

            // Navigators Sections
            ourNavigators: 'Our Navigators',
            ourNavigatorsDescription1: 'Our couriers, known as Navigators, are rigorously selected to give the best service, each one receives specific training so that the order arrives to you in perfect conditions.',
            ourNavigatorsDescription2: 'Designed and manufactured in Portugal with top isothermal materials, Volup backpacks are the key to the success of all our deliveries.',
            applyAsNavigator: "Apply as Navigator",

            // Newsletter Section
            subscribeNewsletter: 'The Newsletter that brings you the best of gastronomy',
            subscribeNewsletterDescription: 'Do you consider yourself a foodie? It is for you and thinking of you that Volup, together with our partners, bring to your comfort the best of gastronomy.\nJoin this family and receive our latest news first hand.',
            subscribe: 'Subscribe',
            newsletter: 'Newsletter',
            newsletterSubscribed: 'Newsletter has been subscribed successfully.',
            serverError: 'Something went wrong while trying to connect to the server.',

            testimonial1: `Volup coming up was the best thing that ever happened to us, we found a partner with whom we totally identify with and where we know that our product is always very well taken care of.`,
            testimonial2: `We consider Volup more than an app, it has become since its first day of existence a business partner, which made us look at a market entirely unknown to us as a great business opportunity, the partnership relationship has been an absolute success and with bright prospects for a growing future.`,
            testimonial3: `Volup has proven to be much more than a home delivery company, betting above all on quality of service, with the goal of elevating the customer's experience, taking to their home the restaurant's identity. Using Volup is undoubtedly a quality experience, much more than just using a simple delivery service.`,
        },
        restaurants: {
            showingFilteredResults: 'Showing results for',
            showingAllResults: 'Showing all results',
            location: 'Location',
            back: 'Back',
            schedule: 'Schedule',
            opened: 'Open',
            closed: 'Closed',
            checkOnApp: 'View on App',
            orderOnApp: 'Order',
            description: 'Description',
            all: 'See All',
        },
        chefs: {
            contentTitle: `Your Chef, Your Home`,
            contentText_1: `We are here to revolutionize your palate and elevate your dining experiences at home.\nWe will make sure you eat with all your senses: with what you see, smell and feel...`,
            contentSubTitle: `How?`,
            contentText_2: `Bringing the best chefs to the comfort of your home \nFind out more about our service and don't forget...\nIt's not just a meal, it's an experience!`,
            exclusive: `An exclusive service`,
            schedules: `Flexible schedules`,
            relax: `Relax, we'll take care of everything`,
            speakwithus: `Talk to us`,
            getintouch: `Or ask for more information at`,

        },
        days: {
            monday: 'MON',
            tuesday: 'TUE',
            wednesday: 'WED',
            thursday: 'THU',
            friday: 'FRI',
            saturday: 'SAT',
            sunday: 'SUN',
        },
        mission: {
            wannaBePart: 'Want to be part of this team?',
            history: 'History',
            history_text1: 'Volup was born in December 2020, with the mission of creating a new concept of delivery - Prime Food, from the best Portuguese chefs, to your home. Since then it has been gaining its place in the market, being known today as a service of excellence, where quality and gastronomic diversity are a guarantee.',
            history_text2: 'Volup provides an experience and combination from Portuguese, French, Japanese... Because the most refined food requires care, Volup rewards in its customer service, in the care and packaging at the time of delivery, so that your favorite dish arrives to you as if it had just left the kitchen.',
            history_text3: 'Volup represents a lifestyle for people who want a gastronomic experience, with comfort, just a click away. In order to enjoy your leisure moments, at home, at work, alone, with family or with friends.',
            team: 'Team',
            team_subtitle: 'Prime Food & Prime Staff',
            team_text1: 'Built by a young, dynamic, responsible and energetic team that wants to change things, its goal is to value and innovate the home delivery service, turning the moment into an experience.',
        },
        languages: {
            portuguese: 'PT',
            english: 'EN',
            spanish: 'ES',
            french: 'FR',
        },
        contact: {
            title: 'Any questions or support requests, please contact us through the form.',
            subTitle: 'Questions about the Application or any other questions you may have.',
            header: 'Contact Form',
            subject: 'Select Subject',
            name: 'Name',
            email: 'e-mail',
            message: 'Your message...',
            send: 'Send',
            subject1: "I want to register my restaurant in Volup",
            subject2: "I want to be navigator",
            subject3: "Press",
            subject4: "Support",
            subject5: "Other",
            success: 'Success',
            emailSent: 'The email was sent successfully.',
            error: 'Error',
            couldNotSend: 'The server could not be reached.',
        },
    },
    es: {
        fields: {
            phone: 'Telefone',
        },
        header: {
            home: 'Home',
            mission: 'Misión',
            restaurants: 'Restaurantes',
            toContact: 'Contacto',
            download: 'Download',
            partner: 'Parceiro Volup',
            news: 'Noticias',
            downloadMenu: 'Download App',
            discover: '{volup} bem mais que uma APP',
            discover2: 'uma experiência',
            language: 'Idioma',
            bannerBtn: 'Ver',
            sendMessage: 'Envie-nos uma mensagem',
        },
        footer: {
            cities: 'Ciudades',
            about: 'Acerca de',
            social: 'Social',
            copyright: 'Copyright 2021',
            volupLimited: 'Volup Limited',
            sendUsEmail: 'Envianos un email',
            blog: 'Blog',
            terms: 'Términos y Condiciones',
            privacy: 'Política de privacidad',
        },
        home: {
            showAllCategories: 'Ver todas as categorias e restaurantes',
            weWork: 'Trabalhamos com os melhores chefs e restaurantes.',
            categories: 'Categorías',
            viewAll: 'Ver todo',
            moreThanApp: 'más que una aplicación,',
            experience: 'una experiencia gastronomica',
            download: 'Los mejores restaurantes y platos de los chefs en tu casa.',
            navigatorsBackpack: 'Navigators e a sua Mochila Volup',
            outDeliveries: 'Prime Food & Prime Delivery.',
            packaging: 'Os estafetas, conhecidos como Navigators, são selecionados rigorosamente, cada um recebe uma formação específica para que o pedido chegue até si em perfeitas condições.',
            prototype: 'Desenhadas e fabricadas em Portugal com materiais isotérmicos de topo, as mochilas Volup são a peça chave para o sucesso e acondicionamento de todas as nossas entregas.',
            backpackAreas: 'Áreas para quente e frio',
            backpackAreasText: 'Porque sabemos que cada elemento tem que ser tratado de forma especial.',
            backpackPackaging: 'Packaging Específico',
            backpackPackagingText: 'A sua refeição é preparada com cuidado, e deve ser entregue também, com o mesmo ou mais cuidado.',
            backpackNavigator: 'Queres ser Navigator Volup?',
            backpackNavigatorButton: 'Abrir Formulário',
            locations: 'Onde estamos?',
            locationsSub: 'Levamos o seu restaurante preferido até onde quiser!',
            everExpanding: 'En constante expansión',
            everExpandingDescription: 'Cada vez más cerca de ti. Volup está en constante crecimiento y trabaja para poder ofrecer toda la calidad, dedicación y entrega, estés donde estés.',
            exclusiveRestaurants: '{0} restaurantes.',
            wereWorking: 'Noticias em breve, estamos a trabalhar para chegar mais perto de si.',
            chefsJoined: 'Os chefs que se juntaram à Volup',
            chefsJoined2: 'têm algo para lhe dizer.',
            itsPossible: 'Ainda tem dúvidas que se deve juntar à Volup?',
            availableOnApps: 'Disponible en iOS y Android',
            downloadApp: 'Descarga la aplicación',
            cookies: 'Volup utiliza cookies para brindar al usuario una mejor experiencia de uso. Al continuar usando nuestros servicios, usted acepta el {link}.',
            cookiePolicy: 'uso de cookies',
            cookieAccept: 'Acceptar',
            cookieDecline: 'Negar',
            multiOrder: 'Multiorder',
            orderWithMultiorder: 'Pedir con Multiorder',
            multiorderDescription: 'Pida hasta 3 restaurantes en un solo pedido. Hace que todo sea más sencillo.',
            exclusiveMenus: 'Menús exclusivos de Volup',
            exclusiveMenusDescription: 'Los mejores chefs preparan platos y menús exclusivos para usted.',

            // Be Partner Section
            bePartner: 'Su canal de venta online para llegar a su tipo de cliente',
            bePartnerDescription: 'Formar parte de Volup significa estar presente en una app que reúne: los mejores restaurantes, las mejores experiencias gastronómicas y el mayor cuidado en el servicio de entrega.\nSomos una extensión de su excelente servicio a tan solo un clic de distancia.',
            michelin: 'Estrellas Michelin',
            highMediumEndRestaurant: 'Restaurantes',
            star: 'Hoteles de 5 estrellas',
            chef: 'Chefs',

            // Navigators Sections
            ourNavigators: 'Nuestros Navigators',
            ourNavigatorsDescription1: 'Nuestros drivers, conocidos como Navigators, son rigurosamente seleccionados para dar el mejor servicio, cada uno recibe una formación específica para que el pedido le llegue en perfectas condiciones.',
            ourNavigatorsDescription2: 'Diseñadas y fabricadas en Portugal con materiales isotérmicos de primera calidad, las mochilas Volup son la clave del éxito en todas nuestras entregas.',
            applyAsNavigator: "Aplicar como Navegador",

            // Newsletter Section
            subscribeNewsletter: 'La Newsletter que te trae lo mejor de la gastronomía',
            subscribeNewsletterDescription: '¿Se considera un amante de la comida? Es para usted y pensando en usted que Volup, junto con sus socios, trae a su comodidad lo mejor de la gastronomía. Únase a esta familia y reciba nuestras noticias de primera mano.',
            subscribe: 'Suscribir',
            newsletter: 'Newsletter',
            newsletterSubscribed: 'Newsletter has been subscribed successfully.',
            serverError: 'Something went wrong while trying to connect to the server.',

            testimonial1: `Volup ha sido lo mejor que nos ha pasado, hemos encontrado un socio con el que nos identificamos totalmente y en el que sabemos que nuestro producto está siempre muy bien cuidado.`,
            testimonial2: `Consideramos a Volup como algo más que una aplicación, se ha convertido desde su primer día de existencia en un socio que nos hizo ver un mercado totalmente desconocido como una gran oportunidad de negocio. La colaboración ha sido un éxito absoluto y con brillantes perspectivas de un futuro creciente.`,
            testimonial3: `Volup ha demostrado ser mucho más que una empresa de delivery, apostando sobre todo por la calidad del servicio, con el objetivo de elevar la experiencia del cliente, llevando la identidad del restaurante a su casa. Utilizar Volup es sin duda una experiencia de calidad, mucho más que utilizar un simple servicio de entrega.`,
        },
        restaurants: {
            showingFilteredResults: 'Se muestran los resultados para',
            showingAllResults: 'Mostrando todos los resultados',
            location: 'Localización',
            back: 'Regreso',
            schedule: 'Hora',
            opened: 'Abierto',
            closed: 'Cerrado',
            checkOnApp: 'Ver en la aplicación',
            orderOnApp: 'Pedir',
            description: 'Descripción',
            all: 'Ver todos',
        },
        chefs: {
            contentTitle: 'O seu Chef, a sua casa',
            contentText_1: `Surgimos no mercado para revolucionar o seu paladar e elevar as suas experiências gastronómicas em casa. \nFaremos questão que coma com todos os seus sentidos: com aquilo que vê, que cheira e que sente…`,
            contentSubTitle: 'Como?',
            contentText_2: `Levando até ao conforto de sua casa os melhores chefes \nFique a conhecer mais deste nosso serviço e não se esqueça..\nNão é apenas um jantar, é uma experiência!`,
            exclusive: `Um serviço \n exclusivo`,
            schedules: `Horários \n Flexiveis`,
            relax: `Relaxe, \n Tratamos de tudo`,
            speakwithus: 'Fale connosco',
            getintouch: 'Ou peça mais informações em',
        },
        days: {
            monday: 'LUN',
            tuesday: 'MAR',
            wednesday: 'MIE',
            thursday: 'JUE',
            friday: 'VIE',
            saturday: 'SAB',
            sunday: 'DOM',
        },
        mission: {
            wannaBePart: 'Quer fazer parte desta equipa?',
            history: 'História',
            history_text1: 'A Volup nasceu em dezembro de 2020, com a missão de criar um novo conceito de Delivery – Prime Food, dos melhores chefes portugueses, em sua casa. Desde aí que tem vindo a ganhar o seu lugar no mercado, sendo hoje conhecida como um serviço de excelência, onde a qualidade e a diversidade gastronómica são garantia.',
            history_text2: 'A Volup proporciona uma experiência e combinação desde a cozinha portuguesa, francesa, à japonesa... Porque a comida mais refinada requer cuidados a Volup premeia no seu serviço ao cliente, nos cuidados e acondicionamento no momento das entregas, para que o seu prato preferido chegue até si como acabado de sair da cozinha.',
            history_text3: 'A Volup representa um estilo de vida para as pessoas que querem uma experiência gastronómica, com conforto, ao alcance de um clique. De forma a aproveitar os seus momentos de lazer, em casa, no trabalho, sozinho, em família ou com amigos.',
            team: 'Equipa',
            team_subtitle: 'Prime Food & Prime Staff',
            team_text1: 'Construída por uma equipa jovem, dinâmica, responsável e cheia de energia, que pretende mudar as coisas.Tem como objetivo valorizar e inovar o serviço de entregas ao domicílio, tornando o momento numa experiência.',
        },
        languages: {
            portuguese: 'PT',
            english: 'EN',
            spanish: 'ES',
            french: 'FR',
        },
        contact: {
            title: 'Cualquier duda o solicitud de soporte, por favor contáctenos usando el formulario.',
            subTitle: 'Questões sobre o Aplicativo ou outras questões que queira colocar.',
            header: 'Formulario de contacto',
            subject: 'Selecciona el tema',
            name: 'Tu nombre',
            email: 'Tu e-mail',
            message: 'Tu mensaje...',
            send: 'Mandar',
            subject1: "Quero ser parceiro Volup",
            subject2: "Candidatar para navigator",
            subject3: "Imprensa",
            subject4: "Suporte",
            subject5: "Outros assuntos",
            success: 'Sucesso',
            emailSent: 'El correo electrónico ha sido enviado.',
            error: 'Erro',
            couldNotSend: 'Não foi possível contactar o servidor.',
        },
    },
    fr: {
        fields: {
            phone: 'Téléphone',
        },
        header: {
            home: 'Home',
            mission: 'Mission',
            restaurants: 'Restaurants',
            toContact: 'Contact',
            download: 'Download',
            partner: 'Partenaire Volup',
            news: 'Nouvelles',
            downloadMenu: 'Download App',
            discover: '{volup} bem mais que uma APP',
            discover2: 'uma experiência',
            language: 'Langue',
            bannerBtn: 'Voir',
            sendMessage: 'Envoie-nous un message',
        },
        footer: {
            cities: 'Villes',
            about: 'Sur',
            social: 'Social',
            copyright: 'Copyright 2021',
            volupLimited: 'Volup Limited',
            sendUsEmail: 'Envoyez-nous un e-mail',
            blog: 'Blog',
            terms: 'Termes et conditions',
            privacy: 'Politique de confidentialité',
        },
        home: {
            showAllCategories: 'Ver todas as categorias e restaurantes',
            weWork: 'Trabalhamos com os melhores chefs e restaurantes.',
            categories: 'Catégories',
            viewAll: 'Voir Tout',
            moreThanApp: `plus qu'une application,`,
            experience: 'une expérience gastronomique',
            download: 'Os melhores restaurantes e pratos de chefes em sua casa.',
            navigatorsBackpack: 'Navigators e a sua Mochila Volup',
            outDeliveries: 'Prime Food & Prime Delivery.',
            packaging: 'Os estafetas, conhecidos como Navigators, são selecionados rigorosamente, cada um recebe uma formação específica para que o pedido chegue até si em perfeitas condições.',
            prototype: 'Desenhadas e fabricadas em Portugal com materiais isotérmicos de topo, as mochilas Volup são a peça chave para o sucesso e acondicionamento de todas as nossas entregas.',
            backpackAreas: 'Áreas para quente e frio',
            backpackAreasText: 'Porque sabemos que cada elemento tem que ser tratado de forma especial.',
            backpackPackaging: 'Packaging Específico',
            backpackPackagingText: 'A sua refeição é preparada com cuidado, e deve ser entregue também, com o mesmo ou mais cuidado.',
            backpackNavigator: 'Queres ser Navigator Volup?',
            backpackNavigatorButton: 'Abrir Formulário',
            locations: 'Onde estamos?',
            locationsSub: 'Levamos o seu restaurante preferido até onde quiser!',
            everExpanding: 'En constante expansion',
            everExpandingDescription: 'Toujours plus près de toi. Volup est en croissance constante et travaille pour pouvoir offrir toute la qualité, le dévouement et la livraison, où que vous soyez.',
            exclusiveRestaurants: '{0} restaurantes.',
            wereWorking: 'Noticias em breve, estamos a trabalhar para chegar mais perto de si.',
            chefsJoined: 'Os chefs que se juntaram à Volup',
            chefsJoined2: 'têm algo para lhe dizer.',
            itsPossible: 'Ainda tem dúvidas que se deve juntar à Volup?',
            availableOnApps: 'Disponível em iOS e Android',
            downloadApp: 'Faça o download da App',
            cookies: `Volup utilise des cookies pour offrir à l'utilisateur une meilleure expérience utilisateur. En poursuivant votre navigation sur nos services, vous acceptez {link}.`,
            cookiePolicy: `l'utilisation de cookies`,
            cookieAccept: 'Accepter',
            cookieDecline: 'Refuser',
            multiOrder: 'Multiorder',
            orderWithMultiorder: 'Commander avec Multiorder',
            multiorderDescription: `Commandez jusqu'à 3 restaurants en une seule fois. Cela rend tout plus simple.`,
            exclusiveMenus: 'Menus exclusifs de Volup',
            exclusiveMenusDescription: 'Les meilleurs chefs cuisiniers préparent pour vous des menus exclusifs.',

            // Be Partner Section
            bePartner: 'Votre canal de vente en ligne pour atteindre votre type de client.',
            bePartnerDescription: `Être partenaire de Volup, c'est être présent dans une application qui réunit: les meilleurs restaurants, les meilleures expériences gastronomiques et le plus grand soin dans le service de livraison.\nNous sommes une extension de votre excellent service, à portée de clic.`,
            michelin: 'Étoiles Michelin',
            highMediumEndRestaurant: 'Restaurants',
            star: 'Hôtels 5 étoiles',
            chef: 'Chefs',

            // Navigators Sections
            ourNavigators: 'Nos navigateurs',
            ourNavigatorsDescription1: 'Nos coursiers, appelés Navigateurs, sont rigoureusement sélectionnés pour offrir le meilleur service, chacun reçoit une formation spécifique afin que la commande vous parvienne dans de parfaites conditions.',
            ourNavigatorsDescription2: `Conçus et fabriqués au Portugal avec les meilleurs matériaux isothermes, les sacs à dos Volup sont la clé du succès et de l'emballage de toutes nos livraisons.`,
            applyAsNavigator: "Postuler en tant que navigateur",

            // Newsletter Section
            subscribeNewsletter: 'La Newsletter qui vous apporte le meilleur de la gastronomie',
            subscribeNewsletterDescription: `Vous considérez-vous comme un gastronome? C'est pour vous et en pensant à vous que Volup, avec ses partenaires, apporte à votre confort le meilleur de la gastronomie.\nRejoignez cette famille et recevez nos nouvelles de première main.`,
            subscribe: `S'abonner`,
            newsletter: 'Newsletter',
            newsletterSubscribed: 'Newsletter has been subscribed successfully.',
            serverError: 'Something went wrong while trying to connect to the server.',

            testimonial1: `L'arrivée de Volup a été la meilleure chose qui nous soit arrivée, nous avons trouvé un partenaire avec lequel nous nous identifions totalement et où nous savons que notre produit est toujours très bien pris en charge.`,
            testimonial2: `Nous considérons que Volup est plus qu'une application, elle est devenue dès le premier jour d'existence un partenaire commercial, ce qui nous a fait regarder un marché entièrement inconnu pour nous comme une grande opportunité commerciale. La relation de partenariat a été un succès absolu et avec de brillantes perspectives pour un avenir croissant.`,
            testimonial3: `Volup s'est révélé être bien plus qu'une société de livraison à domicile, misant avant tout sur la qualité du service, dans le but d'élever l'expérience du client, en amenant l'identité du restaurant chez lui. Utiliser Volup est sans aucun doute une expérience de qualité, bien plus qu'un simple service de livraison.`,
        },
        restaurants: {
            showingFilteredResults: 'Affichage des résultats pour',
            showingAllResults: 'Affichage de tous les résultats',
            location: 'Localisation',
            back: 'Retourner',
            schedule: 'Temps',
            opened: 'Ouvert',
            closed: 'Fermé',
            checkOnApp: `Afficher dans l'application`,
            orderOnApp: 'Ordre',
            description: 'La description',
            all: 'Tout voir',
        },
        chefs: {
            contentTitle: 'O seu Chef, a sua casa',
            contentText_1: `Surgimos no mercado para revolucionar o seu paladar e elevar as suas experiências gastronómicas em casa. \nFaremos questão que coma com todos os seus sentidos: com aquilo que vê, que cheira e que sente…`,
            contentSubTitle: 'Como?',
            contentText_2: `Levando até ao conforto de sua casa os melhores chefes \nFique a conhecer mais deste nosso serviço e não se esqueça..\nNão é apenas um jantar, é uma experiência!`,
            exclusive: `Um serviço \n exclusivo`,
            schedules: `Horários \n Flexiveis`,
            relax: `Relaxe, \n Tratamos de tudo`,
            speakwithus: 'Fale connosco',
            getintouch: 'Ou peça mais informações em',
        },
        days: {
            monday: 'LUN',
            tuesday: 'MAR',
            wednesday: 'MER',
            thursday: 'JEU',
            friday: 'VEN',
            saturday: 'SAM',
            sunday: 'DIM',
        },
        mission: {
            wannaBePart: 'Quer fazer parte desta equipa?',
            history: 'História',
            history_text1: 'A Volup nasceu em dezembro de 2020, com a missão de criar um novo conceito de Delivery – Prime Food, dos melhores chefes portugueses, em sua casa. Desde aí que tem vindo a ganhar o seu lugar no mercado, sendo hoje conhecida como um serviço de excelência, onde a qualidade e a diversidade gastronómica são garantia.',
            history_text2: 'A Volup proporciona uma experiência e combinação desde a cozinha portuguesa, francesa, à japonesa... Porque a comida mais refinada requer cuidados a Volup premeia no seu serviço ao cliente, nos cuidados e acondicionamento no momento das entregas, para que o seu prato preferido chegue até si como acabado de sair da cozinha.',
            history_text3: 'A Volup representa um estilo de vida para as pessoas que querem uma experiência gastronómica, com conforto, ao alcance de um clique. De forma a aproveitar os seus momentos de lazer, em casa, no trabalho, sozinho, em família ou com amigos.',
            team: 'Equipa',
            team_subtitle: 'Prime Food & Prime Staff',
            team_text1: 'Construída por uma equipa jovem, dinâmica, responsável e cheia de energia, que pretende mudar as coisas.Tem como objetivo valorizar e inovar o serviço de entregas ao domicílio, tornando o momento numa experiência.',
        },
        languages: {
            portuguese: 'PT',
            english: 'EN',
            spanish: 'ES',
            french: 'FR',
        },
        contact: {
            title: `Pour toute question ou demande d'assistance, veuillez nous contacter via le formulaire.`,
            subTitle: `Des questions sur l'application ou d'autres questions que vous pourriez avoir.`,
            header: 'Formulaire de contact',
            subject: 'Sélectionner le sujet',
            name: 'Votre nom',
            email: 'Votre e-mail',
            message: 'Votre message...',
            send: 'Envoyer',
            subject1: "Je veux être partenaire Volup",
            subject2: "Demander un navigateur",
            subject3: "Presse",
            subject4: "Soutien",
            subject5: "Un autre sujet",
            success: 'Succès',
            emailSent: `L'email a été envoyé.`,
            error: 'Erro',
            couldNotSend: 'Não foi possível contactar o servidor.',
        },
    },
});
