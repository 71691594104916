import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import reducers from './reducers';
import history from 'utils/history';

export default function configureStore(initialState = {}) {
    const middlewares = [routerMiddleware(history)];
	const enhancers = [applyMiddleware(...middlewares)];

    let composeEnhancers = compose;
    if (process.env.NODE_ENV !== 'production') composeEnhancers = composeWithDevTools({});

            // @ts-ignore
	const store = createStore(reducers, initialState, composeEnhancers(...enhancers));

	// Enable Webpack hot module replacement for reducers
	module.hot?.accept(reducers as any, () => {
		const nextRootReducer = require('./reducers');
		store.replaceReducer(nextRootReducer);
	});
    
	return store;
};

export const store = configureStore();
export const persistor = persistStore(store);