import React from "react";
import { connect } from "react-redux";
import { Col, Drawer, Modal, Row, Select } from 'antd';
import { push } from "connected-react-router";

//banner
//@ts-ignore
// import SmartBanner from "react-smartbanner";
// import "./../../../node_modules/react-smartbanner/dist/main.css";


import { setAppModal, setLanguage } from 'store/actions';
import { getOS } from 'utils/utils';
import { ANDROID_LINK, APPLE_LINK } from 'utils/constants';

import "./styles.scss";
import logo from "assets/images/logo.png";
import logoMini from "assets/images/volup_mini.png";
import downloadApple from "assets/images/appstore.png";
import downloadAndroid from "assets/images/playstore.png";
import Strings from "utils/strings";

const { Option } = Select;

interface Props {
    history?: any;
    dispatch: any;
    transparency: any;
    appModal: any;
    language: any;
}

interface State {
    transparent: boolean;
    scrolled: boolean;
    isMobile: boolean;
    showDrawer: boolean;
    windowWidth: number;
}

export class Header extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            // eslint-disable-next-line no-restricted-globals
            transparent: props.transparency,
            windowWidth: window.innerWidth < 600 ? window.innerWidth - 20 : 600,
            scrolled: false,
            isMobile: document.body.clientWidth <= 992,
            showDrawer: false,
        };

        this.handleResize = this.handleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentDidUpdate(prevProps: Props) {
        const { transparency: prevTransparency } = prevProps;
        const { transparency } = this.props;

        if (transparency !== prevTransparency) {
            this.setState({ transparent: transparency });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleResize() {
        this.setState({ isMobile: document.body.clientWidth <= 992, windowWidth: document.body.clientWidth < 600 ? document.body.clientWidth - 20 : 600, });
    }

    handleScroll() {
        this.setState({ scrolled: window.scrollY > 0, transparent: this.props.transparency && window.scrollY === 0 });
    }

    openBurgerMenu() {
        this.setState((prevState: any) => ({ showDrawer: !prevState.showDrawer }));
    }

    handleDownloadButton() {
        const { dispatch } = this.props;

        const os = getOS();

        if (os === 'Android') {
            window.open(ANDROID_LINK);
        } else if (os === 'iOS') {
            window.open(APPLE_LINK);
        } else {
            dispatch(setAppModal(true));
        }
    }

    renderDrawer() {
        const { showDrawer } = this.state;
        const { dispatch } = this.props;

        return (
            <Drawer
                title={
                    <div className="Mobile_Menu_Header">
                        <img src={logoMini} alt="Mobile Logo" />
                    </div>
                }
                headerStyle={{ height: 56, padding: '0 10px' }}
                placement="left"
                closable={true}
                zIndex={5000}
                contentWrapperStyle={{ width: '100%', maxWidth: 600 }}
                onClose={() => this.openBurgerMenu()}
                visible={showDrawer}
            >
                <a
                    href="/"
                    onClick={(e: any) => {
                        e.preventDefault();
                        this.setState({ showDrawer: false });
                        dispatch(push("/"));
                    }}
                >
                    <span>{Strings.header.home}</span>
                </a>
                <a
                    href="/restaurants"
                    onClick={(e: any) => {
                        e.preventDefault();
                        this.setState({ showDrawer: false });
                        dispatch(push("/restaurants"));
                    }}
                >
                    <span>{Strings.header.restaurants}</span>
                </a>
                <a href="/#multiorder">
                    <span>{Strings.home.multiOrder}</span>
                </a>
                <a
                    href="/mission"
                    onClick={(e: any) => {
                        e.preventDefault();
                        this.setState({ showDrawer: false });
                        dispatch(push("/mission"));
                    }}
                >
                    <span>{Strings.header.mission}</span>
                </a>
                <a
                    href="/contact"
                    onClick={(e: any) => {
                        e.preventDefault();
                        this.setState({ showDrawer: false });
                        dispatch(push("/contact"));
                    }}
                >
                    <span>{Strings.header.toContact}</span>
                </a>
                <a
                    href="/#brands"
                >
                    <span>{Strings.header.news}</span>
                </a>
                <a href="/#partner">
                    <span>{Strings.header.partner}</span>
                </a>
                <div className="Mobile_Language_Picker">
                    <span>{Strings.header.language}</span>
                    <Select
                        value={this.props.language}
                        style={{ width: 60, borderRadius: 0, border: '1px solid #F0F0F0' }}
                        onChange={(value: string) => {
                            Strings.setLanguage(value);
                            dispatch(setLanguage(value));
                            this.forceUpdate();
                        }}
                        dropdownStyle={{ textAlign: 'center' }}
                        bordered={false}
                        showArrow={false}
                    >
                        <Option value="pt">{Strings?.languages?.portuguese}</Option>
                        <Option value="en">{Strings?.languages?.english}</Option>
                        <Option value="es">{Strings?.languages?.spanish}</Option>
                        <Option value="fr">{Strings?.languages?.french}</Option>
                    </Select>
                </div>
                <button onClick={() => this.handleDownloadButton()} className="Download_Button">
                    {Strings.header.download}
                </button>
            </Drawer>
        );
    }

    mobileRender() {
        const { dispatch } = this.props;
        const { transparent, scrolled } = this.state;

        return (
            <header className="Header_Container">
                <div className={`Header_Content${transparent ? " __transparent" : " __filled"}${scrolled ? ' __scrolled' : ''}`}>
                    <div className="Header_Inner_Container">
                        <a
                            href="/"
                            onClick={(e: any) => {
                                e.preventDefault();
                                dispatch(push("/"));
                            }}
                            className="Header_Logo"
                        >
                            <img src={logo} alt="logo" />
                        </a>
                        <button onClick={() => this.handleDownloadButton()} className="Download_Button_Header">
                            {Strings.header.downloadMenu}
                        </button>
                        <button
                            onClick={() => this.openBurgerMenu()}
                            className="Header_Burger">
                            <em className="moon-Menu" />
                        </button>
                    </div>
                </div>
            </header>
        );
    }

    renderModal() {
        const { windowWidth } = this.state;
        const { appModal, dispatch } = this.props;

        return (
            <Modal
                centered
                width={windowWidth}
                visible={appModal || false}
                footer={null}
                title={null}
                closable={true}
                onCancel={() => dispatch(setAppModal(false))}
                bodyStyle={{
                    minHeight: 420,
                }}
            >
                {this.renderModalContent()}
            </Modal>
        );
    }

    renderModalContent() {
        return (
            <React.Fragment>
                <div className="Modal_Apps_Overlay" />
                <div className="Modal_Apps_Container">
                    <Row gutter={[20, 20]}>
                        <Col xs={24}>
                            <span className="Modal_Title">{Strings.formatString(Strings.header.discover as any, { volup: <span style={{ fontWeight: 700 }}>Volup</span> })}</span>
                            <span className="Modal_Title">{Strings.header.discover2}</span>
                            <span className="Modal_SubTitle">{Strings.home.downloadApp}</span>
                        </Col>
                        <Col xs={24}>
                            <div className="Modal_App_Buttons">
                                <a href={APPLE_LINK} target="_blank" rel="noreferrer">
                                    <img src={downloadApple} alt="download on app store" />
                                </a>
                                <a href={ANDROID_LINK} target="_blank" rel="noreferrer">
                                    <img src={downloadAndroid} alt="download on google play" />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { dispatch } = this.props;
        const { transparent, isMobile } = this.state;

        return (
            <React.Fragment>
                {isMobile ? (
                    this.mobileRender()
                ) : (
                    <header className="Header_Container">
                        <div className={`Header_Content${transparent ? " __transparent" : " __filled"}${this.props.transparency ? ' __scrolled' : ''}`}>
                            <div className="Header_Inner_Container">
                                <a
                                    href="/"
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        dispatch(push("/"));
                                    }}
                                    className="Header_Logo"
                                >
                                    <img src={logo} alt="logo" />
                                </a>
                                <nav className="Header_Nav">
                                    <a
                                        href="/"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            dispatch(push("/"));
                                        }}
                                    >
                                        <span>{Strings.header.home}</span>
                                    </a>
                                    <a
                                        href="/restaurants"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            dispatch(push("/restaurants"));
                                        }}
                                    >
                                        <span>{Strings.header.restaurants}</span>
                                    </a>
                                    <a
                                        href="/#multiorder"
                                    >
                                        <span>{Strings.home.multiOrder}</span>
                                    </a>
                                    {/* <a
                                        href="/chefathome"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            dispatch(push("/chefathome"));
                                        }}
                                    >
                                        <span>Chef at Home</span>
                                    </a> */}
                                    <a
                                        href="/mission"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            dispatch(push("/mission"));
                                        }}
                                    >
                                        <span>{Strings.header.mission}</span>
                                    </a>
                                    <a
                                        href="/contact"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            dispatch(push("/contact"));
                                        }}
                                    >
                                        <span>{Strings.header.toContact}</span>
                                    </a>
                                    <a 
                                        style={{ color: 'white' }}
                                        href="https://blog.volup.app/" 
                                        onClick={(e: any) => { e.preventDefault(); window.open('https://blog.volup.app/') }}
                                    >
                                        Blog
                                    </a>
                                    {/* <a
                                        href="/#brands"
                                    >
                                        <span>{Strings.header.news}</span>
                                    </a> */}
                                    <a
                                        className="Header_Volup_Banner"
                                        href="/#partner"
                                    >
                                        <span>{Strings.header.partner}</span>
                                    </a>
                                    <Select
                                        value={this.props.language}
                                        style={{ width: 60, borderRadius: 0, border: '2px solid #F0F0F0' }}
                                        onChange={(value: string) => {
                                            Strings.setLanguage(value);
                                            dispatch(setLanguage(value));
                                            this.forceUpdate();
                                        }}
                                        dropdownStyle={{ textAlign: 'center' }}
                                        bordered={false}
                                        showArrow={false}
                                    >
                                        <Option value="pt">{Strings?.languages?.portuguese}</Option>
                                        <Option value="en">{Strings?.languages?.english}</Option>
                                        <Option value="es">{Strings?.languages?.spanish}</Option>
                                        <Option value="fr">{Strings?.languages?.french}</Option>
                                    </Select>
                                    <button onClick={() => this.handleDownloadButton()} className="Download_Button">
                                        {Strings.header.download}
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </header>
                )}
                {this.renderDrawer()}
                {this.renderModal()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    transparency: state.transparency,
    appModal: state.appModal,
    language: state.language,
});

export default connect(mapStateToProps)(Header);
