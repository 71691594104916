import React from 'react';
import { connect } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
import { setTransparency } from 'store/actions';
import { get, uriPages } from 'utils/api';
import { translate } from 'utils/utils';

import './styles.scss';

export class Terms extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            html: '',
            title: '',
            loading: true,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(setTransparency(false));
        
        this.getPage();
        setTimeout(() => this.setState({ loading: false }), 500);
    }

    getPage = async () => {
		const response = await get(`${uriPages()}?type=tc`) as any;

		if (response.status >= 200 && response.status < 400) {
			const { pages } = response.data.results || {};

            if (Array.isArray(pages) && pages.length) {
                const page = pages[0];
                this.setState({ html: page.content, title: page.title });
            }
		}
	}

    render() {
        const { html, title, loading } = this.state;

        return (
            <React.Fragment>            
                <div className={`App_Loader${loading ? '' : ' __loaded'}`}>
                    {loading && (
                        <ClapSpinner size={60} loading={true} frontColor="#F5A623" backColor="#0A0A1B" />
                    )}
                </div>
                <div className="Privacy_Container">
                    <div className="Privacy_Background" />
                    <div className="Privacy_Content">
                        <h1>{translate(title)}</h1>
                        <div dangerouslySetInnerHTML={{ __html: translate(html) }} />
                    </div>
                </div>
            </React.Fragment>
        );
    };
}

export default connect()(Terms);