import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { push, replace } from "connected-react-router";
import { RestaurantCard } from "components";
import { Col, Row } from "antd";
import Carousel from "react-multi-carousel";
import { ClapSpinner } from "react-spinners-kit";

// @ts-ignore
import ReactFitText from 'react-fittext';
import { get, uriRestaurants } from "utils/api";
import { setTransparency } from "store/actions";
import { translate } from "utils/utils";

import "./styles.scss";
import Strings from "utils/strings";
import placeholder from 'assets/images/placeholders/product.png';

export class Restaurants extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            selectedCategory: props.router?.location.state?.category || null,
            selectedLocation: "all",
            categories: [],
            banners: [],
            businesses: [],
            allBusinessess: [],
            allLocations: [],
            loading: true,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(setTransparency(false));
        dispatch(replace('/restaurants', null))

        this.getData();
    }

    componentDidUpdate(prevProps: any) {
        const { language } = this.props;

        if (prevProps.language !== language) {
            this.forceUpdate();
        }
    }

    async getData() {
        this.setState({ loading: true });

        const response = await get(uriRestaurants()) as any;
        if (response.status >= 200 && response.status < 400) {
            const { businesses = [], banners = [], categories = [] } = response.data.results || {};

            const allBusinessess = [];
            const allLocations = businesses.map((business: any) => business._id);
            for (let city of businesses) {
                allBusinessess.push(...city.restaurants);
            }

            this.setState({ businesses, allBusinessess, allLocations, banners, categories });
        }

        this.setState({ loading: false });
    }

    selectCategory(category: any) {
        const { allBusinessess, selectedLocation } = this.state;
        let newLocation = selectedLocation;

        if (selectedLocation !== "all") {
            const filteredBusinesses = allBusinessess.filter((business: any) => business.categories.some((cat: any) => cat._id === category._id));
            const hasLocation = filteredBusinesses.some((business: any) => business.region === selectedLocation);

            if (!hasLocation) newLocation = "all";
        }
        
        this.setState({ selectedCategory: category, selectedLocation: newLocation }, () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    }

    renderBanners() {
        const { banners } = this.state;
        const { dispatch } = this.props;

        const carouselResponsive = {
            desktop: {
                breakpoint: { max: 3840, min: 769 },
                items: 3,
                slidesToSlide: 1,
            },
            mobile: {
                breakpoint: { max: 768, min: 0 },
                items: 1,
                slidesToSlide: 1,
            },
        };

        return (
            <div className="Restaurant_Banners">
                <Row gutter={[50, 15]}>
                    <Col xs={24}>
                        <Carousel
                            responsive={carouselResponsive}
                            swipeable={true}
                            draggable={true}
                            infinite={true}
                            arrows={false}
                            showDots={true}
                            autoPlay={true}
                            autoPlaySpeed={5000}
                            renderDotsOutside
                            itemClass="carousel-banner-padding"
                        >
                            {banners.map((banner: any, index: any) => (
                                <div
                                    key={`banner_${index}`}
                                    style={{
                                        backgroundImage:
                                            `url(${translate(banner.image)})`,
                                    }}
                                    onClick={() => {
                                        if (banner?.business) {
                                            dispatch(push(`restaurant/${banner?.business}`))
                                        }
                                    }}
                                    className="Restaurant_Banner __clickable"
                                />
                            ))}
                        </Carousel>
                    </Col>
                </Row>
            </div>
        );
    }

    renderCategories() {
        const { categories } = this.state;

        const carouselResponsive = {
            desktop: {
                breakpoint: { max: 4000, min: 1280 },
                items: 8,
                slidesToSlide: 8,
            },
            semi: {
                breakpoint: { max: 1280, min: 1024 },
                items: 6,
                slidesToSlide: 6,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 4,
                slidesToSlide: 4,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2,
                slidesToSlide: 2,
            },
        };

        return (
            <div className="Restaurant_Carousel">
                <Carousel
                    responsive={carouselResponsive}
                    swipeable={false}
                    draggable={false}
                    infinite={true}
                    arrows={true}
                    itemClass="carousel-item-padding"
                >
                    {categories.map((category: any, index: number) => (
                        <div
                            key={`restaurant_category_${index}`}
                            className="Restaurant_Card"
                            style={{ backgroundImage: `url(${category.image || placeholder})` }}
                            onClick={() => this.selectCategory(category)}
                        >
                            <div className="Restaurant_Name">
                                <ReactFitText compressor={0.6} minFontSize={13} maxFontSize={20}>
                                    <span>{translate(category.name)}</span>
                                </ReactFitText>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        );
    }

    renderList() {
        const { selectedCategory, selectedLocation, categories, allBusinessess, allLocations } = this.state;
        const { dispatch } = this.props;

        let businesses = allBusinessess;
        let locations = allLocations;
        if (selectedCategory) {
            businesses = allBusinessess.filter((business: any) => business.categories.some((category: any) => category._id === selectedCategory._id));

            locations = [...new Set(businesses.map((business: any) => business.region))];
        }

        if (selectedLocation !== "all") {
            businesses = businesses.filter((business: any) => business.region === selectedLocation);
        }

        return (
            <div className="Restaurant_List">
                <Row>
                    <Col xs={24}>
                        <span className="Restaurant_Title">
                            {Strings.header.restaurants}
                        </span>
                    </Col>
                    <Col xs={24}>
                        <span className="Restaurant_Search_Status">
                            {selectedCategory
                                ? Strings.restaurants.showingFilteredResults
                                : Strings.restaurants.showingAllResults}
                        </span>
                    </Col>
                </Row>
                <Row style={{ marginTop: selectedCategory ? 10 : 20 }} gutter={[30, 30]}>
                    <Col xs={24} lg={5}>
                        {Boolean(selectedCategory) && (
                            <div
                                style={{
                                    paddingTop: 0,
                                    height: 130,
                                    cursor: 'default',
                                    backgroundImage: `url(${selectedCategory.image})`
                                }}
                                className="Restaurant_Card"
                            >
                                <div style={{ height: 50 }} className="Restaurant_Name">
                                    <span>{translate(categories.find((category: any) => category._id === selectedCategory._id)?.name)}</span>
                                </div>
                            </div>
                        )}
                        <div className="Search_Location">
                            <span>{Strings.restaurants.location}</span>
                            <div className="Search_Location_Options">
                                <button className={`Search_Location_Button${selectedLocation === 'all' ? " __selected" : ""}`} onClick={() => this.setState({ selectedLocation: 'all' })}>
                                    {Strings.restaurants.all}
                                </button>
                                {locations.map((location: any, index: number) => (
                                    <button
                                        key={`location_button_${index}`}
                                        className={`Search_Location_Button${selectedLocation === location ? " __selected" : ""
                                            }`}
                                        onClick={() =>
                                            this.setState((prevState: any) => ({ selectedLocation: location }))
                                        }
                                    >
                                        {location}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} lg={19}>
                        <Row gutter={[10, 10]}>
                            {businesses
                                .map((elem: any, index: number) => (
                                    <Col key={`restaurant_card_${index}`} xs={24} md={12} lg={8}>
                                        <RestaurantCard
                                            image={elem.image}
                                            location={elem.city}
                                            restaurant={elem.name}
                                            type={elem.categories.map((category: any) => translate(category.name)).join(', ')}
                                            onClick={() => dispatch(push(`/restaurant/${elem._id}`))}
                                        />
                                    </Col>
                                ))}
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    renderBack() {
        return (
            <Row>
                <Col xs={24}>
                    <button onClick={() => this.setState({ selectedCategory: null })} className="Restaurants_Back">
                        <em className="moon-arrow" />
                        <span>{Strings.restaurants.back}</span>
                    </button>
                </Col>
            </Row>
        );
    }

    render() {
        const { selectedCategory, loading } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Volup | Restaurantes</title>
                    <meta name="description" content="Restaurantes description" />
                </Helmet>
                <div className={`App_Loader${loading ? '' : ' __loaded'}`}>
                    {loading && (
                        <ClapSpinner size={60} loading={true} frontColor="#F5A623" backColor="#0A0A1B" />
                    )}
                </div>
                <div className="Restaurants_Container">
                    <div className="Restaurants_Background" />
                    <div className="Restaurants_Content">
                        {!selectedCategory && this.renderBanners()}
                        {!selectedCategory && this.renderCategories()}
                        {Boolean(selectedCategory) && this.renderBack()}
                        {this.renderList()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
    router: state.router,
});

export default connect(mapStateToProps)(Restaurants);
