import React from 'react';
import { connect } from 'react-redux';
import { Col, Input, Row, Select, notification } from 'antd';
import { post, uriContact } from 'utils/api';

import Strings from 'utils/strings';
import './styles.scss';
import { setTransparency } from 'store/actions';

const { Option } = Select;
const { TextArea } = Input;

export class Contact extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            subject: null,
            name: '',
            email: '',
            message: '',
            canSubmit: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(setTransparency(false));
    }

    openNotification = ({ placement, type, title, text }: { placement: any, type: String, title: String, text: String }) => {
        switch (type) {
            case 'warn':
            case 'wearning': {
                notification.warn({
                    message: title,
                    description: text,
                    placement,
                });

                break;
            }
            case 'success': {
                notification.success({
                    message: title,
                    description: text,
                    placement,
                });

                break;
            }
            case 'error': {
                notification.error({
                    message: title,
                    description: text,
                    placement,
                });

                break;
            }
        }
    };

    async submitForm(event: any) {
        event.preventDefault();
        const { subject, name, email, message } = this.state;

        if (!this.canSubmit()) return;

        const response = await post(uriContact(), {
            type: subject.type,
            subject,
            name,
            email,
            message
        }) as any;

        if (response.status >= 200 && response.status < 400) {
            this.openNotification({ placement: 'bottomRight', type: 'success', title: Strings.contact.success as String, text: Strings.contact.emailSent as String });
            this.setState({
                subject: null,
                name: '',
                email: '',
                message: '',
                canSubmit: false,
            });
        } else {
            this.openNotification({ placement: 'bottomRight', type: 'error', title: Strings.contact.error as String, text: Strings.contact.couldNotSend as String });
        }
    }

    canSubmit() {
        const { subject, name, email, message } = this.state;

        return Boolean(subject) && Boolean(name?.trim()) && Boolean(email?.trim()) && Boolean(message?.trim());
    }

    render() {
        const { name, email, message, subject } = this.state;

        return (
            <div className="Contact_Container">
                <div className="Contact_Background" />
                <div className="Contact_Content">
                    <span className="Contact_Title">{Strings.contact.title}</span>
                    {/* <span className="Contact_SubTitle">{Strings.contact.subTitle}</span> */}
                    <div className="Contact_Form_Container">
                        <form id="contact_form" className="Contact_Form" onSubmit={(e) => this.submitForm(e)}>
                            <div className="formInnerWrapper">
                                <h2>{Strings.contact.header}</h2>
                                <Row gutter={[20, 20]}>
                                    <Col xs={24}>
                                        <Select
                                            value={subject}
                                            style={{ width: '100%' }}
                                            size="large"
                                            placeholder={Strings.contact.subject}
                                            onChange={(key: any) => this.setState({ subject: key })}
                                        >
                                            <Option value="PARTNER">{Strings.contact.subject1}</Option>
                                            <Option value="PRESS">{Strings.contact.subject3}</Option>
                                            <Option value="SUPPORT">{Strings.contact.subject4}</Option>
                                            <Option value="OTHER">{Strings.contact.subject5}</Option>
                                        </Select>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Input
                                            type="text"
                                            size="large"
                                            required
                                            value={name || ''}
                                            placeholder={Strings.contact.name}
                                            onChange={(e: any) => this.setState({ name: e.target.value })}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Input
                                            type="email"
                                            size="large"
                                            required
                                            value={email || ''}
                                            placeholder={Strings.contact.email}
                                            onChange={(e: any) => this.setState({ email: e.target.value })}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextArea
                                            size="large"
                                            required
                                            value={message || ''}
                                            placeholder={Strings.contact.message}
                                            onChange={(e: any) => this.setState({ message: e.target.value })}
                                            rows={5}
                                        />
                                    </Col>
                                </Row>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                    <button type="submit">
                                        {Strings.contact.send}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(Contact);