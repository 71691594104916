import React from 'react';
import { connect } from "react-redux";
import Carousel from 'react-multi-carousel';

import { getOS, translate } from "utils/utils";
import { ANDROID_LINK, APPLE_LINK } from 'utils/constants';
import { setAppModal } from "store/actions";

import Strings from 'utils/strings';
import './styles.scss';

export class RestaurantCard extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            isMobile: document.body.clientWidth <= 768,
        };

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize() {
        this.setState({ isMobile: document.body.clientWidth <= 768 });
    }

    handleDownloadButton() {
        const { dispatch } = this.props;

        const os = getOS();

        if (os === "Android") {
            window.open(ANDROID_LINK);
        } else if (os === "iOS") {
            window.open(APPLE_LINK);
        } else {
            dispatch(setAppModal(true));
        }
    }

    openApp(id: string) {
        const { dispatch, restaurant } = this.props;
        const os = getOS();

        if (os === "Android") {
            const win = window.open(`https://m.volup.app/business/${id}?name=${restaurant}`);
            setTimeout(() => win?.location.replace(ANDROID_LINK), 500);
        } else if (os === "iOS") {
            const win = window.open(`https://m.volup.app/business/${id}?name=${restaurant}`);
            setTimeout(() => win?.location.replace(APPLE_LINK), 500);
        } else {
            dispatch(setAppModal(true));
        }
    }

    renderImages() {
        const { images } = this.props;

        const carouselResponsive = {
            allDevices: {
                breakpoint: { max: 4000, min: 0 },
                items: 1
            },
        };

        if (!Array.isArray(images) || !images.length) return null;

        return (
            <Carousel
                responsive={carouselResponsive}
                swipeable={true}
                draggable={true}
                arrows={false}
                showDots={true}
                autoPlay={true}
            >
                {images.map((image: string, index: number) => (
                    <div key={`restaurant_image_${index}`} className="Restaurant_Carousel_Image">
                        <img src={image} alt={`restaurant ${index}`} />
                    </div>
                ))}
            </Carousel>
        )
    }

    render() {
        const { image, images, location, restaurant, type, onClick, id, description } = this.props;
        const os = getOS();
        const isMobile = (os === "Android" || os === "iOS");

        return (
            <div onClick={onClick} className={`Restaurant_Card_Container${Boolean(onClick) ? ' __navigation' : ''}`}>
                {((!Array.isArray(images) || !images.length) && (
                    <div style={{ backgroundImage: `url(${image})` }} className="Restaurant_Card_ImageContainer" />
                )) || this.renderImages()}
                {Array.isArray(images) ? (
                    <div>
                        <div className="Restaurant_Card_Title_Options">
                            <div style={{ flex: 1 }} className="Restaurant_Card_Title">
                                <span className="Restaurant_Card_Location">{location}</span>
                                <span className="Restaurant_Card_Restaurant">{restaurant}</span>
                                <span className="Restaurant_Card_Type">{type}</span>
                            </div>
                            <div className="Restaurant_Card_Option">
                                <button onClick={() => this.openApp(id)} className="Download_Button">
                                    <span>{isMobile ? Strings.restaurants.orderOnApp : Strings.header.downloadMenu}</span>
                                </button>
                            </div>
                        </div>
                        <div className="Restaurant_Card_Description">
                            {/* <h3>{translate(description) ? Strings.restaurants.description : ''} </h3> */}
                            <p>{translate(description)}</p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="Restaurant_Card_Title">
                            <span className="Restaurant_Card_Location">{location}</span>
                            <span className="Restaurant_Card_Restaurant">{restaurant}</span>
                            <span className="Restaurant_Card_Type">{type}</span>
                        </div>
                        <div className="Restaurant_Card_Description">
                            <h3>{translate(description) ? Strings.restaurants.description : ''} </h3>
                            <p>{translate(description)}</p>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({});
export default connect(mapStateToProps)(RestaurantCard);