import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Col, Row, Tabs } from "antd";
import { RestaurantCard, ProductCard } from "components";
import { DateTime, Duration } from "luxon";
import { ClapSpinner } from "react-spinners-kit";

import { get, uriRestaurants } from "utils/api";
import { translate } from "utils/utils";
import { setTransparency } from "store/actions";

import "./styles.scss";
import Strings from "utils/strings";
import placeholderWhite from 'assets/images/placeholders/placeholder-white.png';

const { TabPane } = Tabs;

/* eslint-disable */
export class Restaurant extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            schedule: [],
            categories: [],
            products: [],
            groups: [],
            loading: true,
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(setTransparency(false));

        this.getData();

        document.addEventListener("scroll", this.handleScroll);
    }

    componentDidUpdate(prevProps: any) {
        const { language } = this.props;

        if (prevProps.language !== language) {
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.handleScroll);
    }

    async getData() {
        const { match } = this.props;
        this.setState({ loading: true });

        const response = (await get(uriRestaurants(match.params?.id))) as any;
        if (response.status >= 200 && response.status < 400) {
            const { businesses: business } = response.data.results || {};
            const groups = business.items.map((item: any) => ({
                name: item.group.name,
                _id: item.group._id,
            }));
            const products = [];

            for (let item of business.items) {
                products.push({
                    group: item.group._id,
                    products: item.items,
                });
            }

            this.setState({
                business,
                gallery: business.gallery?.map((image: any) => image.url) || [],
                location: business.location,
                groups,
                schedule: business.schedule,
                selectedTab: `product_tab_${groups[0]._id}`,
                products,
            });
        }

        this.setState({ loading: false });
    }

    isVisible(elem?: any) {
        if (!(elem instanceof Element))
            throw Error("DomUtil: elem is not an element.");
        const style = getComputedStyle(elem);
        if (style.display === "none") return false;
        if (style.visibility !== "visible") return false;
        // @ts-ignore
        if (style.opacity < 0.1) return false;
        
        if (
            // @ts-ignore
            elem.offsetWidth +
            // @ts-ignore
            elem.offsetHeight +
            elem.getBoundingClientRect().height +
            elem.getBoundingClientRect().width ===
            0
        ) {
            return false;
        }
        const elemCenter = {
            // @ts-ignore
            x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
            // @ts-ignore
            y: elem.getBoundingClientRect().top + elem.offsetHeight / 2,
        };
        if (elemCenter.x < 0) return false;
        if (
            elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)
        )
            return false;
        if (elemCenter.y < 0) return false;
        if (
            elemCenter.y >
            (document.documentElement.clientHeight || window.innerHeight)
        )
            return false;
        let pointContainer = document.elementFromPoint(elemCenter.x, elemCenter.y);
        do {
            if (pointContainer === elem) return true;

            // @ts-ignore
        } while ((pointContainer = pointContainer?.parentNode));
        return false;
    }

    handleScroll() {
        const { groups } = this.state;

        for (let group of groups) {
            const groupElement = document.getElementById(`product_tab_${group._id}`);

            if (groupElement && this.isVisible(groupElement)) {
                this.setState({ selectedTab: `product_tab_${group._id}` });
                break;
            }
        }
    }

    getDay(day: number) {
        switch (day) {
            case 0:
                return Strings.days.monday;
            case 1:
                return Strings.days.tuesday;
            case 2:
                return Strings.days.wednesday;
            case 3:
                return Strings.days.thursday;
            case 4:
                return Strings.days.friday;
            case 5:
                return Strings.days.saturday;
            case 6:
                return Strings.days.sunday;
        }
    }

    renderBack() {
        const { dispatch } = this.props;

        return (
            <Row>
                <Col xs={24}>
                    <button
                        onClick={() => dispatch(push("/restaurants"))}
                        className="Restaurant_Back"
                    >
                        <em className="moon-arrow" />
                        <span>{Strings.restaurants.back}</span>
                    </button>
                </Col>
            </Row>
        );
    }

    renderRestaurantHeader() {
        const { match } = this.props;
        const { gallery = [], location, business } = this.state;

        return (
            <Row gutter={[20, 20]}>
            <Col xs={24} lg={/*translate(business?.description) ? */ 15 /* : 24 */}>
                    <RestaurantCard
                        images={gallery}
                        location={business?.city}
                        restaurant={business?.name}
                        type={business?.categories?.map((category: any) => translate(category.name)).join(', ')}
                        id={match?.params?.id}
                        description={business?.description}
                    />
                </Col>
                <Col xs={24} lg={9}>
                    {this.renderSchedule()}
                </Col>
            </Row>
        );
    }

    renderProducts() {
        const { products, groups, selectedTab } = this.state;

        return (
            <React.Fragment>
                <div className="Restaurant_Tabs">
                    <Tabs
                        onChange={(key) => {
                            const elem = document.getElementById(key);
                            const body = document.body;

                            if (elem && body) {
                                const bodyRect = body.getBoundingClientRect(),
                                    elemRect = elem.getBoundingClientRect(),
                                    offset = elemRect.top - bodyRect.top - 170;

                                window.scrollTo({ top: offset, behavior: "smooth" });
                            }
                        }}
                        activeKey={selectedTab}
                        centered
                    >
                        {groups.map((group: any, index: number) => {
                            return (
                                <TabPane
                                    tab={translate(group.name)}
                                    key={`product_tab_${group._id}`}
                                />
                            );
                        })}
                    </Tabs>
                </div>
                <div className="Restaurant_Products">
                    <Row gutter={[20, 20]}>
                        {products.map((group: any, index: number) => {
                            const groupSection = groups.find(
                                (tempGroup: any) => tempGroup._id === group.group
                            );

                            return (
                                <div
                                    id={`product_tab_${group.group}`}
                                    style={{
                                        width: "100%",
                                        rowGap: 20,
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                    key={`category_${index}`}
                                >
                                    <Col xs={24}>
                                        <h3 style={{ margin: '10px 0 0', borderBottom: '1px solid #F1F1F1' }}>{translate(groupSection.name)}</h3>
                                    </Col>
                                    {group.products.map((product: any, index: number) => {
                                        return (
                                            <Col key={`product_${index}`} xs={24} md={12} xl={8}>
                                                <ProductCard
                                                    name={translate(product.name)}
                                                    image={product.image || placeholderWhite}
                                                    isPlaceholder={!Boolean(product.image)}
                                                    description={translate(product.description)}
                                                    price={product.price}
                                                    specialPrice={product.specialPrice}
                                                />
                                            </Col>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    renderSchedule() {
        const { schedule } = this.state;

        const today = DateTime.utc().weekday - 1;
        const actualTime = DateTime.utc().toFormat("HH:mm");
        const actualMillis = Duration.fromISOTime(actualTime).toMillis();
        const isOpen = schedule[today]?.timeSlots.some(
            (slot: any) => slot.start < actualMillis && slot.end > actualMillis
        );

        return (
            <div className="Restaurant_Schedule">
                <div className="Schedule_Status">
                    <span className="Schedule_Title">{Strings.restaurants.schedule}</span>
                    <span className={`Schedule_Open${isOpen ? "" : " __closed"}`}>
                        {isOpen ? Strings.restaurants.opened : Strings.restaurants.closed}
                    </span>
                </div>
                {schedule.map((day: any, index: number) => {
                    return (
                        <div key={`day_${index}`} className="Schedule_Row">
                            <div className="Schedule_Day">
                                <span className={today === index ? "Schedule_Highlight" : ""}>
                                    {this.getDay(index)}
                                </span>
                            </div>
                            <div className="Schedule_Slots">
                                {day?.timeSlots.map((slot: any, idx: number) => {
                                    return (
                                        <span
                                            key={`slot_${idx}`}
                                            className={today === index ? "Schedule_Highlight" : ""}
                                        >
                                            {Duration.fromMillis(slot.start).toFormat("hh:mm")} -{" "}
                                            {Duration.fromMillis(slot.end).toFormat("hh:mm")}
                                        </span>
                                    );
                                })}
                                {!day?.timeSlots?.length && (
                                    <div className="Schedule_Closed">
                                        {Strings.restaurants.closed}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        const { loading, business } = this.state;
        const { language } = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Volup | ${business?.name}`}</title>
                    <meta name="description" content={translate(business?.description)} />
                </Helmet>
                <div className={`App_Loader${loading ? "" : " __loaded"}`}>
                    {loading && (
                        <ClapSpinner
                            size={60}
                            loading={true}
                            frontColor="#F5A623"
                            backColor="#0A0A1B"
                        />
                    )}
                </div>
                <div className="Restaurant_Container">
                    <div className="Restaurant_Background" />
                    <div className="Restaurant_Content">
                        {this.renderBack()}
                        {this.renderRestaurantHeader()}
                        <Row gutter={[20, 20]}>
                            <Col xs={24}>
                                {this.renderProducts()}
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
});

export default connect(mapStateToProps)(Restaurant);
