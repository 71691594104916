import * as TYPES from '../constants';
import { connectRouter } from 'connected-react-router';
import history from 'utils/history';

export const router = connectRouter(history);

export function loader(state = false, action: any) {
	if (action.type === TYPES.SET_LOADER) {
		return action.value;
	}

	return state;
}

export function transparency(state = false, action: any) {
    if (action.type === TYPES.SET_TRANSPARENCY) {
		return action.value;
	}

    return state;
}

export function appModal(state = false, action: any) {
    if (action.type === TYPES.SET_APP_MODAL) {
		return action.value;
	}

    return state;
}

export function language(state = 'pt', action: any) {
    if (action.type === TYPES.SET_LANGUAGE) {
		return action.value;
	}

    return state;
}