import React from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

export const PinnableMapComponent = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            zoom={19}
            defaultOptions={{
                streetViewControl: false,
                scaleControl: false,
                mapTypeControl: false,
                panControl: true,
                zoomControl: true,
                rotateControl: true,
                fullscreenControl: true,
                scrollwheel: false
            }}
            defaultCenter={{
                // @ts-ignore
                lat: props.location?.[1] || 38.743008991273015,
                // @ts-ignore
                lng: props.location?.[0] || -9.147909879684448
            }}
        >
            <Marker
                position={{
                    // @ts-ignore
                    lat: props.location?.[1] || 38.743008991273015,
                    // @ts-ignore
                    lng: props.location?.[0] || -9.147909879684448
                }}
            />
        </GoogleMap>
    ))
);

export default PinnableMapComponent;