import React, { useState } from 'react';
// @ts-ignore
import ReactPhoneInput from 'react-phone-input-material-ui';
import { TextField } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import 'react-phone-input-2/lib/style.css'
import Strings from 'utils/strings';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#ff5000' },
    },
    typography: { useNextVariants: true } as any,
});


function PhoneField(props: any) {
  const { value, onChange } = props;
  const [focus, setFocus] = useState(false);

  return (
    <ThemeProvider theme={theme}>
        <label className={`PhoneLabel${focus ? ' __active' : ''}`}>
            {Strings.fields.phone}
        </label>
        <ReactPhoneInput
          value={value}
          country="pt"
          onChange={onChange}
          inputStyle={{ border: 'none', width: '100%' }}
          buttonStyle={{ backgroundColor: '#FFF', border: 'none' }}
          component={TextField}
          placeholder="912345678"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
    </ThemeProvider>
  );
}

export default PhoneField;